import React from 'react'
import '../../styles/Forms.scss'
import {ServiceEnum} from '../../../model/ServiceEnum'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../util/routing'
import {NewCompanyItemDescription} from '../description/NewCompanyItemDescription'
import {getCompanyItemsFromFile} from '../../readImportedFile'
import {useCreateDefaultServiceRequestMutation} from '../../rtkServiceRequestApi'
import {ROUTES} from '../../../../constants/routing'
import {ItemMatchingInputDTO, NewItemInputDTO} from '../../../model/NewItemInput'
import {array, mixed, object} from 'yup'
import {ServiceRequestFormTemplate} from '../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ServiceRequestStep} from '../../model/ServiceRequestStep'
import {UploadTemplateItemCreation} from '../common/UploadTemplateItemCreation'

export interface CustomerItemCreationForm {
    file: File | undefined
    items: NewItemInputDTO[] | ItemMatchingInputDTO[]
}

const validationSchema = object<CustomerItemCreationForm>({
    file: mixed().required(),
    items: array().required().min(1)
})

export const NewCompanyItemsForm = () => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createRequest, {isLoading}] = useCreateDefaultServiceRequestMutation()

    function handleCompleteUploadForm(itemCreation: CustomerItemCreationForm): Promise<any> | undefined {
        if (!itemCreation.file) return
        if (!itemCreation.items) return

        return createRequest({
            service: ServiceEnum.NEW_COMPANY_ITEMS,
            guildId: currentGuildContext?.guildId ?? '',
            file: itemCreation.file,
            items: itemCreation.items as NewItemInputDTO[]
        })
    }

    async function handleFileUpload(tempFile: File) {
        return await getCompanyItemsFromFile(tempFile)
    }

    const steps: ServiceRequestStep<CustomerItemCreationForm>[] = [
        {
            name: 'Create Company Items',
            component: <UploadTemplateItemCreation description={NewCompanyItemDescription} handleFileUpload={handleFileUpload}/>,
            pageValidation: validationSchema
        }
    ]

    return <ServiceRequestFormTemplate
        steps={steps}
        handleFormSubmit={handleCompleteUploadForm}
        initialValues={{
            file: undefined,
            items: []
        }}
        title="Create Company Items"
        previousUrl={generatePath(ROUTES.newPartsServiceForm.path)}
    ></ServiceRequestFormTemplate>

}
