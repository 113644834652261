import {WorkflowConfiguration} from './model/WorkflowConfiguration'
import ServiceWorkflowConfiguration from './ServiceWorkflowConfiguration'
import React from 'react'
import {availableServices} from './WorkflowConfigurationManagement'

const WorkflowConfigurationTable = ({
                                        workflowConfigurations
                                    }: {
    workflowConfigurations: WorkflowConfiguration[]
}) => {
    return (
        <>
            {availableServices.map((service) => {
                const existingConfigurations = workflowConfigurations.filter((conf) => conf.service === service)
                return existingConfigurations.map(existingConfiguration =>
                    <ServiceWorkflowConfiguration
                        key={`id-${existingConfiguration.id}`}
                        workflowConfiguration={
                            //Unsure if all guilds have their own workflow configurations for all available services in the database
                            //=> iterating through available services to display all available services in frontend
                            existingConfiguration
                        }
                    />
                )
            })}
        </>
    )
}

export default WorkflowConfigurationTable
