import React, {ChangeEvent} from 'react'
import './SearchResultPageSelection.scss'
import {useParams, useSearchParams} from 'react-router-dom'
import {ApplyFilterDTO, ApplyFilterItemTypeDTO, ApplyFilterOrgDTO, Search} from '../model/SearchFilter'
import {selectCurrentUser} from '../../guild/rtkGuildApi'
import {useSelector} from 'react-redux'
import {useCurrentGuildContext} from '../../util/routing'
import {
    definitionIdUrlSearchParamsName,
    directionURLSearchParamsName,
    fieldURLSearchParamsName,
    SortingDirection,
    SortingField,
    toSortingDirection,
    toSortingField
} from '../model/Sorting'
import {
    isPublicSearchParamsName,
    managedByGuildIdUrlSearchParamsName,
    notDefinedByOrganizationsUrlParamsName,
    pageSizeURLSearchParamsName,
    pageURLSearchParamsName,
    phraseUrlSearchParamsName,
    visibleByGuildIdUrlSearchParamsName
} from '../SearchParams'
import {
    SearchResultPageForClassificationNode
} from './components/SearchResultContainer/SearchResultPageForClassificationNode'
import {
    SearchResultPageForSpecificItemsSharing
} from './components/SearchResultContainer/SearchResultPageForSpecificItemsSharing'
import {SearchResultPageForPhrase} from './components/SearchResultContainer/SearchResultPageForPhrase'
import {toPageSize} from '../../util/component/search'


const SearchResultPageSelection = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)
    const phrase = searchParams.get(phraseUrlSearchParamsName) ?? undefined
    const page = +(searchParams.get(pageURLSearchParamsName) ?? 1)
    const pageSize = toPageSize(searchParams.get(pageSizeURLSearchParamsName))
    const {classificationId, sharingId} = useParams()

    const sortingDirection: SortingDirection = toSortingDirection(searchParams?.get(directionURLSearchParamsName))
    const sortingField: SortingField = toSortingField(searchParams?.get(fieldURLSearchParamsName))
    const sortingDefinitionId = searchParams?.get(definitionIdUrlSearchParamsName)

    const managedByGuildId = searchParams?.get(managedByGuildIdUrlSearchParamsName) ?? undefined
    const visibleByGuildId = searchParams?.get(visibleByGuildIdUrlSearchParamsName) ?? undefined


    const fetchFilters = () => {
        const urlFilters: string | null = searchParams.get('filters')
        if (!urlFilters) return []
        try {
            const parsedFilters: ApplyFilterDTO[] = JSON.parse(urlFilters)
            return parsedFilters
        } catch (e) {
            searchParams.set('filters', '')
            return []
        }
    }
    const fetchOrgFilters = () => {
        const urlFilters: string | null = searchParams.get('organizationFilter')
        if (!urlFilters) return
        try {
            const parsedFilters: ApplyFilterOrgDTO = JSON.parse(urlFilters)

            return parsedFilters
        } catch (e) {
            searchParams.set('organizationFilter', '')
            return null
        }
    }


    const fetchItemTypesFilters = () => {
        const urlFilters: string | null = searchParams.get('itemTypeFilter')
        if (!urlFilters) return
        try {
            const parsedFilters: ApplyFilterItemTypeDTO = JSON.parse(urlFilters)
            return parsedFilters
        } catch (e) {
            searchParams.set('itemTypeFilter', '')
            return null
        }
    }

    const fetchNotDefinedByOrganizationsFilters = () => {
        const urlFilters: string | null = searchParams.get(notDefinedByOrganizationsUrlParamsName)
        if (!urlFilters) return
        try {
            const parsedFilters: ApplyFilterOrgDTO = {organizationIds: JSON.parse(urlFilters)}
            return parsedFilters
        } catch (e) {
            searchParams.delete(notDefinedByOrganizationsUrlParamsName)
            return null
        }
    }

    const fetchIsPublicFilter = () => {
        const urlFilters: string | null = searchParams.get(isPublicSearchParamsName)
        if (!urlFilters) return
        try {
            return JSON.parse(urlFilters)
        } catch (e) {
            searchParams.delete(isPublicSearchParamsName)
            return null
        }
    }

    const search: Search = {
        phrase: phrase ? {phraseDetail: phrase, isExact: false} : null,
        technicalClassificationNodeId: classificationId,
        guildId: currentGuildContext?.guildId,
        filters: fetchFilters(),
        organizationFilter: fetchOrgFilters(),
        itemTypeFilter: fetchItemTypesFilters(),
        notDefinedByOrganizationsFilter: fetchNotDefinedByOrganizationsFilters(),
        visibleByGuildId: visibleByGuildId,
        isPublicFilter: fetchIsPublicFilter(),
        managedByGuildFilter: managedByGuildId,
        sorting: {
            direction: sortingDirection,
            field: sortingField,
            ...sortingDefinitionId && {definitionId: sortingDefinitionId}
        }
    }

    const handlePageChange = (e: ChangeEvent<unknown>, pageNumber: number) => {
        if (pageNumber === page || page < 0) {
            return
        }

        if (search?.phrase)
            searchParams.set(pageURLSearchParamsName, encodeURIComponent(search?.phrase?.phraseDetail))
        else searchParams.delete(pageURLSearchParamsName)

        searchParams.set(pageURLSearchParamsName, `${pageNumber}`)
        searchParams.set(pageSizeURLSearchParamsName, `${pageSize}`)
        setSearchParams(searchParams)

    }

    if (!user) return <></>
    if (classificationId) return <SearchResultPageForClassificationNode page={page} search={search}
                                                                        classificationId={classificationId}
                                                                        pageSize={pageSize}
                                                                        handlePageChange={handlePageChange}/>
    if (sharingId) return <SearchResultPageForSpecificItemsSharing page={page} search={search} pageSize={pageSize}
                                                                   handlePageChange={handlePageChange} />
    return <SearchResultPageForPhrase page={page} search={search} pageSize={pageSize}
                                      handlePageChange={handlePageChange}/>
}

export default SearchResultPageSelection

