import React, {useEffect, useState} from 'react'
import {Box, Button, Checkbox, Divider, Grid, IconButton, Paper, Tooltip} from '@mui/material'
import {ItemListCard} from './card/ItemListCard'
import './ItemLists.scss'
import CreateNewList from './CreateNewList/CreateNewList'
import LoadingSpinner from '../genericComponents/LoadingSpinner'
import {NoData} from '../genericComponents/NoData'
import DeleteItemListDialog from './DeleteItemList/DeleteItemListDialog'
import {ItemListSelectable} from './model/ItemListSelectable'
import {Link, useNavigate} from 'react-router-dom'
import {IdType} from '../util/models/IdType'
import {ControlPointDuplicate, Create, DeleteForever, Merge, People} from '@mui/icons-material'
import RenameListDialog from './RenameList/RenameListDialog'
import ShareWithGuildDialog from './ShareWithGuildItemList/ShareWithGuildDialog'
import {useDuplicateItemListMutation, useGetItemListsQuery, useMergeItemListsMutation} from './rtkItemListApi'

import {ItemList} from './model/ItemList'
import {selectCurrentUser} from '../guild/rtkGuildApi'
import {useSelector} from 'react-redux'
import {useCurrentGuildContext, useDatxPathGenerator} from '../util/routing'
import {ROUTES} from '../constants/routing'
import {skipToken} from '@reduxjs/toolkit/query/react'

enum EditActions {
    DELETE,
    SHARE,
    DELETE_SELECTION,
    RENAME
}

export const getIsListNameDuplicated = (myLists: ItemList[], listName: string) => {
    // check if the name is duplicated in the existing lists
    // case insensitive
    return myLists?.some(
        (itemList) =>
            itemList.name?.trim().toLowerCase() === listName.trim().toLowerCase()
    )
}

export const isLessThanOneListSelected = (myLists: ItemListSelectable[]) => {
    return myLists.filter((list) => list.selected).length < 2
}

export const ItemLists = () => {
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()

    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)
    const {data: myLists = [], isFetching: myListsLoading} = useGetItemListsQuery(currentGuildContext?.guildId ?? skipToken)
    const [mergeItemLists, mergeItemListsResult] = useMergeItemListsMutation()


    const [checkAll, setCheckAll] = useState(false)
    const [isEmptyItemSelection, setIsEmptyItemSelection] = useState(true)
    const [isNotDeletableItemSelection, setIsNotDeletableItemSelection] = useState(true)
    const [isMergeDisabled, setisMergeDisabled] = useState(true)
    const [itemLists, setItemLists] = useState<ItemListSelectable[]>([])
    const [displayedDialog, setDisplayedDialog] = useState<EditActions | null>(null)
    const [listUnderAction, setListUnderAction] = useState<IdType | null>(null)


    const onActionSelection = ({listId, actionType, deletedLists} : {listId : IdType | null, actionType: EditActions | null, deletedLists?: IdType[] | null}) => {
        setListUnderAction(listId)
        setDisplayedDialog(actionType)
        if(deletedLists && deletedLists.length > 0) {
            setIsEmptyItemSelection(true)
            setIsNotDeletableItemSelection(true)
        }
    }


    const initialiseListSelection = () => {
        setIsEmptyItemSelection(true)
        setCheckAll(false)
        setisMergeDisabled(true)
        setIsNotDeletableItemSelection(true)
    }

    const reDirectToListItems = (itemListId: IdType) => {
        navigate(generatePath(ROUTES.detailItemList.path, {itemListId : itemListId}))
    }

    useEffect(() => {

        initialiseListSelection()
    }, [currentGuildContext])

    useEffect(() => {
        if (myLists?.length >= 0) {
            const selectableItemLists = myLists.map((list) => {
                return {list, selected: false}
            })
            setItemLists(selectableItemLists)
        }

    }, [myLists])



    const selectAllLists = () => {
        const updatedCheckAll = !checkAll
        itemLists.forEach((list) => (list.selected = updatedCheckAll))
        setCheckAll(updatedCheckAll)
        setIsNotDeletableItemSelection(!updatedCheckAll)

        setItemLists(itemLists)

        const noSelectedList = !itemLists.some((list) => list.selected)
        setIsEmptyItemSelection(noSelectedList)
        setisMergeDisabled(isLessThanOneListSelected(itemLists))
    }

    useEffect(() => {
        handleDeletableItemList()
    }, [itemLists])

    const handleDeletableItemList = () => {
        let hasOnlyPersonalItemList = true

        itemLists.filter((list) => list.selected).forEach((itemList) => {
            if ((itemList.list.userOauthId != user?.oauthProviderId))
                hasOnlyPersonalItemList = false
        })

        if (!isEmptyItemSelection && hasOnlyPersonalItemList) {
            setIsNotDeletableItemSelection(false)
        } else {
            setIsNotDeletableItemSelection(true)
        }
    }

    const handleChildChecked = ({listId, selected} : {listId: IdType, selected: boolean}) => {
        const newSelectedLists = itemLists.map((list) => list.list.id === listId ? {...list, selected: selected} : list)
        const noSelectedList = !newSelectedLists.some((list) => list.selected)
        const allSelectedList = newSelectedLists.every((list) => list.selected)

        setIsEmptyItemSelection(noSelectedList)
        setisMergeDisabled(isLessThanOneListSelected(newSelectedLists))
        setCheckAll(allSelectedList)
        setItemLists(newSelectedLists)
    }



    const deleteLists = () => {
        setDisplayedDialog(EditActions.DELETE_SELECTION)
    }

    const mergeLists = () => {
        const selectedListIds = itemLists.filter((list) => list.selected)
            .map((list) => list.list.id)
        currentGuildContext &&
        mergeItemLists({
            guildId: currentGuildContext?.guildId,
            itemListIds: selectedListIds
        })

        initialiseListSelection()

    }

    const retrieveSelectedLists =(itemLists :  ItemListSelectable[]) =>{
        return itemLists.filter((list) => list.selected).map(list => list.list)
    }

    return (
        <div className="item-list-page">

        <Box sx={{flex: 'auto'}}>
            {currentGuildContext ? (
                    <Paper className="all-list-page">

                        <div className="header-title">
                            Manage Lists
                        </div>

                        <div className="item-list-header">
                            <Checkbox
                                data-testid="itemlist-check-all"
                                checked={checkAll}
                                onClick={() => selectAllLists()}
                                title="Select all lists"
                            />
                            <Divider
                                orientation="vertical"
                                variant="middle"
                                flexItem
                            />
                            <div className="header-icons">
                                <Tooltip title="Delete selected lists">
                                    <IconButton
                                        className="delete-button"
                                        aria-label="delete"
                                        disabled={isNotDeletableItemSelection}
                                        onClick={deleteLists}
                                        color="primary"
                                    >

                                        <DeleteForever/>
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Merge selected lists">
                                    <IconButton
                                        data-testid="merge-itemlists"
                                        className="merge-button"
                                        aria-label="merge"
                                        disabled={isMergeDisabled || mergeItemListsResult.isLoading}
                                        onClick={mergeLists}
                                        color="primary"
                                    >
                                        <Merge/>
                                    </IconButton>
                                </Tooltip>
                                {currentGuildContext && <CreateNewList myLists={myLists}/>}
                            </div>
                            <div className="import-list">
                                <Tooltip title="Import lists">
                                    <Button component={Link} to={generatePath(ROUTES.importItemLists.path)}
                                            color="primary" variant="contained">Import List</Button>
                                </Tooltip>
                            </div>
                        </div>
                        <Divider sx={{mb: 2}}/>
                        <Grid container spacing={2}>
                            {myListsLoading ? (
                                <LoadingSpinner/>
                            ) : itemLists?.length > 0 ? (
                                itemLists?.map((itemList) => {
                                    return (

                                        <Grid key={itemList.list.id}
                                              item xs={3}
                                              onClick={() => reDirectToListItems(itemList.list.id)}
                                              data-testid="list-present"
                                              aria-label={`card-name-${itemList.list.name}`}
                                        >
                                            <ItemListCard
                                                list={itemList.list}
                                                key={itemList.list.id}
                                                onSelection={
                                                    handleChildChecked
                                                }

                                                actionList={<MoreOptions
                                                    itemList={itemList}
                                                    onActionSelection={onActionSelection}/>
                                                }
                                                isSelected={itemList.selected}
                                            />
                                        </Grid>
                                    )
                                })
                            ) : (
                                <NoData message="No list available!"/>
                            )
                            }
                        </Grid>
                    </Paper>
            ) : (
                <p className="all-list-message">
                Please select a guild to display your lists
                </p>
            )}
            <ItemListDialogs
                displayedDialog={displayedDialog}
                onClose={(deletedLists) => onActionSelection({listId: null, actionType: null, deletedLists})}
                myLists={myLists}
                itemLists={listUnderAction ? [myLists.find((list) => list.id === listUnderAction)] : retrieveSelectedLists(itemLists)}

            />

        </Box>
        </div>
    )


}

const ItemListDialogs = ({displayedDialog, onClose, myLists, itemLists} : { displayedDialog: EditActions | null, onClose: (deletedLists?: IdType[]) => void, myLists : ItemList[],
    itemLists : (ItemList | undefined)[]}) => {
    return itemLists && itemLists.length ? (
        <>
            <RenameListDialog
                open={displayedDialog === EditActions.RENAME}
                closeDialog={onClose}
                myLists={myLists}
                selectedList={itemLists[0]!}
            />
            <DeleteItemListDialog
                open={displayedDialog === EditActions.DELETE || displayedDialog === EditActions.DELETE_SELECTION}
                closeDialog={onClose}
                myLists={itemLists.filter(item => item !== undefined) as ItemList[]}
            />
        </>) : <></>

}


export const MoreOptions = ({itemList, onActionSelection}: { itemList: ItemListSelectable,
    onActionSelection: ({listId, actionType} : {listId: IdType, actionType: EditActions}) => void}) => {
    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)



    const [isSharedOrUnsharedDialogOpen, setIsSharedOrUnsharedDialogOpen] = useState(false)
    const [duplicateItemList, duplicateItemListResult] = useDuplicateItemListMutation()

    const handleOnClickDeleteOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        onActionSelection({listId: itemList.list.id, actionType: EditActions.DELETE})
    }


    const handleOnClickRenameOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        onActionSelection({listId: itemList.list.id, actionType: EditActions.RENAME})
    }

    const handleOnClickSharedOrUnsharedOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        setIsSharedOrUnsharedDialogOpen(true)
    }

    const handleOnDuplicateOption = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (currentGuildContext) {
            duplicateItemList({
                guildId: currentGuildContext.guildId,
                itemListId: itemList.list.id
            })

        }
    }

    return (
        <>
            {(itemList.list.userOauthId == user?.oauthProviderId)
                && (<>
                        <Button className="more-actions-button"
                                data-testid="rename-option"
                                onClick={handleOnClickRenameOption}>
                            <Create className={'more-actions-option'}/>
                            <div className={'more-actions-title'}>Rename</div>
                        </Button>


                        <Button className="more-actions-button"
                                data-testid="delete-option"
                                onClick={handleOnClickDeleteOption}>
                            <DeleteForever className={'more-actions-option'}/>
                            <div className={'more-actions-title'}>Delete</div>
                        </Button>

                    </>
                )}

            <Button className="more-actions-button"
                    data-testid="duplicate-option"
                    onClick={handleOnDuplicateOption}
                    disabled={duplicateItemListResult.isLoading}>
                <ControlPointDuplicate className={'more-actions-option'}/>
                <div className={'more-actions-title'}>Duplicate list</div>
            </Button>

            {((itemList.list.userOauthId == user?.oauthProviderId) && currentGuildContext?.guildAdmin) && (<>
                    <ShareWithGuildDialog
                        open={isSharedOrUnsharedDialogOpen}
                        closeDialog={() => setIsSharedOrUnsharedDialogOpen(false)}
                        selectedList={itemList}
                    />
                    <Button className="more-actions-button"
                            data-testid="shared-with-option"
                            onClick={handleOnClickSharedOrUnsharedOption}>
                        <People className={'more-actions-option'}/>
                        <div
                            className={'more-actions-title'}>{itemList.list.isSharedWithGuild ? 'Stop sharing with guild' : 'Share with guild'}</div>
                    </Button>
                </>

            )
            }
        </>
    )
}

export default ItemLists
// TODO
// Refactor?
// Error management
// ...
