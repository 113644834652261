import React from 'react'
import LoadingButton from '@mui/lab/LoadingButton'
import './ConfirmServiceSubscriptionActionDialog.scss'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import { Button } from '@mui/material'
import { useCancelServiceSubscriptionMutation } from '../rtkServiceSubscriptionApi'
import { ROUTES } from '../../../constants/routing'
import { useNavigate } from 'react-router-dom'
import { useCurrentGuildContext, useDatxPathGenerator, useMatchedRoute } from '../../../util/routing'

const ConfirmServiceSubscriptionActionDialog = ({ open, closeDialog ,confirmationMsg, title, serviceRequestId}:
     {
         open: boolean,
         closeDialog: any,
         confirmationMsg: string,
         serviceRequestId: string,
         title: string,
     }
) => {
    const [cancelServiceSubscription, cancelServiceSubscriptionResult] = useCancelServiceSubscriptionMutation()
    const navigate  = useNavigate()
    const generatePath = useDatxPathGenerator()
    const route = useMatchedRoute()?.[0].route
    const [currentGuildContext] = useCurrentGuildContext()

    const onSubmit = () => {
        if(!currentGuildContext) {
            return
        }

        const resp = cancelServiceSubscription(serviceRequestId).then(() => {
            closeDialog()
            navigate(generatePath(route === ROUTES.manageServiceSubscription ? ROUTES.guildInactiveServiceSubscriptions.path : ROUTES.userInactiveServiceSubscriptions.path))        
        })
        
    }

    
    return (
        <CustomDialog
            className="subscription-action-dialog"
            open={open}
            title={title}
            onClose={closeDialog}
        >
            <div>
                <span className="subscription-action-dialog-text">{confirmationMsg}</span>

                <div className="subscription-action-dialog-buttons">
                    <Button
                        className="subscription-action-dialog-button"
                        onClick={closeDialog}
                    >
                        Cancel
                    </Button>

                    <LoadingButton
                        className="subscription-action-dialog-submit"
                        color="primary"
                        variant="contained"
                        onClick={onSubmit}
                        data-testid = "confirm-action-subscription"
                        loading={cancelServiceSubscriptionResult.isLoading}
                    >
                        Confirm
                    </LoadingButton>
                </div>


            </div>
        </CustomDialog>
    )
}

export default ConfirmServiceSubscriptionActionDialog
