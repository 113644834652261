import React from 'react'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../util/routing'
import {mixed, object, string} from 'yup'
import {ROUTES} from '../../../../../constants/routing'
import {useCreatePrivateArtifactServiceRequestMutation} from '../../../rtkServiceRequestApi'
import {ItemList} from '../../../../../itemList/model/ItemList'
import {UploadDocumentCreation} from './UploadTemplateDocumentCreation_step2'
import {ReviewAndSubmitDocumentCreation} from './ReviewAndSubmitDocumentCreation_step3'
import {
    createExcelFileObject, generateDownloadableExcelFile,
    generateTemplateFileName,
    getCommonHeader,
    getCommonItemsForExcelForCreation
} from '../../model/PrivateAttributeExcelFileUtil'
import * as XLSX from 'xlsx'
import {ServiceRequestFormTemplate} from '../../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ItemListWithItems} from '../../../../../itemList/model/ItemListWithItems'
import {DefineCreationDocumentCreation} from './DefineCreationDocumentCreation_step1'

export interface AttributeDocumentCreationForm {
    itemList: ItemList | undefined
    attributeName: string | undefined
    itemListWithItems: ItemListWithItems | undefined
    artifactFile: File | undefined
}

export const PrivateAttributeDocumentCreation = () => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createRequest] = useCreatePrivateArtifactServiceRequestMutation()

    const defineCreationValidation = {
        itemList: object().required(),
        attributeName: string().required().max(100)
    }

    const uploadArtifactValidation = {
        artifactFile: mixed().required()
    }

    const submitValidation = object<AttributeDocumentCreationForm>({
        ...defineCreationValidation,
        ...uploadArtifactValidation
    })

    const generateDetailsFile = (formResult: AttributeDocumentCreationForm): File => {
        const fileName = generateTemplateFileName(formResult.itemList?.name, formResult.attributeName)
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = '.xlsx'

        let headers = getCommonHeader()
        //PrivateAttributeManagement
        let itemsForExcel = getCommonItemsForExcelForCreation(formResult.itemListWithItems?.elements ?? [])

        headers = [
            [...headers[0], 'PRIVATE_ATTRIBUTE'],
            [...headers[1], formResult.attributeName + ' (Label)']
        ]

        itemsForExcel = itemsForExcel.map(row => [...row, formResult.artifactFile?.name ?? ''])

        const ws = XLSX.utils.aoa_to_sheet([...headers, ...itemsForExcel])

        // Write to workbook
        return createExcelFileObject(ws, fileType, fileExtension, fileName)
    }

    const steps: ServiceRequestStep<AttributeDocumentCreationForm>[] = [
        {
            name: 'Define Creation',
            component: <DefineCreationDocumentCreation
                itemListSelectLabel="Upload document for items in this list"></DefineCreationDocumentCreation>,
            pageValidation: object<AttributeDocumentCreationForm>({
                ...defineCreationValidation
            })
        },
        {
            name: 'Upload Document',
            component: <UploadDocumentCreation></UploadDocumentCreation>,
            pageValidation: object<AttributeDocumentCreationForm>({...uploadArtifactValidation})
        },
        {
            name: 'Review and Submit',
            component: <ReviewAndSubmitDocumentCreation></ReviewAndSubmitDocumentCreation>,
            pageValidation: submitValidation
        }
    ]

    const handleFormSubmit = (formResult: AttributeDocumentCreationForm): Promise<any> | undefined => {
        if (!formResult.attributeName) return
        if (!formResult.itemListWithItems) return
        if (!formResult.artifactFile) return

        return createRequest({
            guildId: currentGuildContext?.guildId ?? '',
            itemList: formResult.itemList,
            label: formResult.attributeName,
            artifactFile: formResult.artifactFile,
            file: generateDetailsFile(formResult)
        })
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            withStepper={true}
            handleFormSubmit={handleFormSubmit}
            initialValues={{
                itemList: undefined,
                attributeName: undefined,
                itemListWithItems: undefined,
                artifactFile: undefined
            }}
            title="Create New Private Attribute"
            previousUrl={generatePath(ROUTES.selectTypeCreationPrivateAttribute.path)}
        />
    )
}
