import React, {useState} from 'react'
import {DataGrid, GridColDef, GridRenderCellParams} from '@mui/x-data-grid'
import {ServiceRequest} from '../../model/ServiceRequest'
import {getSortedDate} from '../../../util/component/table'
import {GridCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import {
    formatIdLink,
    getFileName,
    getModifiedDate,
    getService,
    getStatus
} from '../../../genericComponents/serviceRequest/ServiceRequestFormatter'
import UserServiceRequestContent from '../../../genericComponents/serviceRequest/UserServiceRequestContent'
import ServiceRequestDialog from '../../../genericComponents/serviceRequest/ServiceRequestDialog'
import LoadingSpinner from '../../../genericComponents/LoadingSpinner'

const getDisplayedValueForQuotationFile = (v1: any) => {
    if (!v1?.row?.file?.status) return ''
    if (v1.row.file.status === 'Valid') {
        return v1?.row?.file?.name
    }
    return v1?.row?.file?.status
}

const UserServiceRequestsTable = ({userServiceRequests, isLoading}: {
    userServiceRequests: ServiceRequest[],
    isLoading: boolean
}) => {

    const [selectedServiceRequest, setSelectedServiceRequest] = useState<GridCellParams>()
    const [open, setOpen] = useState(false)
    const onServiceRequestClick = (params: GridCellParams) => {
        setSelectedServiceRequest(params)
        setOpen(true)
    }

    const columns: GridColDef[] = [
        {
            field: 'id', headerName: 'Request ID',
            flex: 0, minWidth: 100,
            disableColumnMenu: true,
            renderCell: (params: GridRenderCellParams) => formatIdLink(params, onServiceRequestClick),
        },
        {
            field: 'service', headerName: 'Service',
            flex: 1, minWidth: 200,
            disableColumnMenu: true,
            renderCell: getService
        },
        {
            field: 'fileName', headerName: 'Details',
            flex: 3, minWidth: 300,
            disableColumnMenu: true,
            renderCell: getFileName,
            valueGetter: (row) => getDisplayedValueForQuotationFile(row),
            sortComparator: (v1, v2) => v1.localeCompare(v2)
        },
        {
            field: 'status', headerName: 'Status',
            flex: 1, minWidth: 150,
            disableColumnMenu: true,
            renderCell: getStatus
        },
        {
            field: 'createdDate', headerName: 'Creation Date',
            flex: 1, minWidth: 150,
            disableColumnMenu: true,
            renderCell: getModifiedDate,
            sortComparator: (v1, v2) => getSortedDate(v1, v2)
        },
        {
            field: 'updatedDate', headerName: 'Last Modified',
            flex: 1, minWidth: 150,
            disableColumnMenu: true,
            renderCell: getModifiedDate,
            sortComparator: (v1, v2) => getSortedDate(v1, v2)
        }
    ]

    return (
        <div className="guild-member-grid-container" data-testid="guild-member-grid-container">
            <DataGrid
                loading={isLoading}
                slots={{loadingOverlay: LoadingSpinner}}
                autoHeight
                getRowId={(userServiceRequest: ServiceRequest) => userServiceRequest?.id}
                rows={userServiceRequests}
                columns={columns}
                sortingOrder={['desc', 'asc']}
                initialState={{
                    pagination: {paginationModel: {page: 0, pageSize: 20}},
                    sorting: {
                        sortModel: [
                            {
                                field: 'updatedDate',
                                sort: 'desc',
                            },
                        ],
                    },
                }}
            />
            <ServiceRequestDialog
                open={open}
                onClose={() => setOpen(false)}
                serviceRequestId={selectedServiceRequest?.row.id}>
                {selectedServiceRequest && (
                    <UserServiceRequestContent selectedServiceRequest={selectedServiceRequest}/>
                )}
            </ServiceRequestDialog>
        </div>
    )
}
export default UserServiceRequestsTable

