import '../Header.scss'
import React, {useEffect, useState} from 'react'
import {useTypedDispatch} from '../../util/store'
import {useLocation} from 'react-router-dom'
import {Menu, MenuItem} from '@mui/material'
import {useAccount, useMsal} from '@azure/msal-react'
import {getEmailFromMsal} from '../../authentication/AuthHooksAndFunctions'
import {LocalStorage} from '../../util/localStorage'
import CustomHeaderButton from '../headerGenericComponents/CustomHeaderButton'
import {ExitToApp, Tune} from '@mui/icons-material'
import {GuildContext} from '../models/GuildContext'
import {CustomNestedMenuItem} from '../../genericComponents/customMenu/NestedMenu'
import IconMenuItem from '../../genericComponents/customMenu/IconMenuItem'
import {useGetUserQuery} from '../../guild/rtkGuildApi'
import {useCurrentGuildContext} from '../../util/routing'

const UserMenu = () => {
    const {instance} = useMsal()
    const accountInfo = useAccount()
    const {pathname} = useLocation()
    const [email, setEmail] = useState(getEmailFromMsal(instance))
    const [activeLink, setActiveLink] = useState(false)
    const dispatch = useTypedDispatch()
    const [currentGuildContext, setCurrentGuild] = useCurrentGuildContext()
    const {data: user, isFetching: userIsLoading} = useGetUserQuery()
    const [anchorEl, setAnchorEl] = useState<Element | null>(null)
    const open = Boolean(anchorEl)
    const handleClick = (e: React.MouseEvent) => setAnchorEl(e.currentTarget)
    const handleClose = () => setAnchorEl(null)

    useEffect(() => {
        setEmail(getEmailFromMsal(instance))
    }, [instance, accountInfo])

    useEffect(() => {
        if (!user) {
            return
        }
        setEmail(user?.email)
    }, [user])

    useEffect(() => {
        setActiveLink(pathname.startsWith('/user'))
    }, [pathname])

    const handleSelectGuildContext = (guildContext: GuildContext | any) => {
        setCurrentGuild(guildContext?.guildId)
        handleClose()
    }

    const logoutRequest = {
        postLogoutRedirtcetUri: 'https://www.datx.eu',
        idTokenHint: instance?.getActiveAccount()?.idToken,
    }

    const handleLogout = () => {
        LocalStorage.setUserEmail('')
        instance.logoutRedirect(logoutRequest)
        handleClose()
    }

    return (
        <div>
            <CustomHeaderButton
                onClick={handleClick}
                activeNavLink={activeLink}
            >
                <div className="dropDownButton">
                    <p className="guildLabel">
                        {currentGuildContext?currentGuildContext?.guildName:
                            <div className = "noGuild">Select A Guild</div>}
                    </p>
                    {currentGuildContext &&
                        currentGuildContext?.guildAdmin != null && (
                            <p className="guildUserType">
                                {currentGuildContext?.guildAdmin
                                    ? 'Guild Admin'
                                    : 'Guild Member'}

                            </p>
                        )}
        </div>

            </CustomHeaderButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <p className="emailLabel" data-testid="user-email-label">{email}</p>
                <CustomNestedMenuItem
                    leftIcon={<Tune/>}
                    label="Guild Context"
                    parentMenuOpen={open}
                    className="guildContext"
                >
                    {[
                        <MenuItem key="no-guild" data-testid="no-guild" onClick={() => handleSelectGuildContext(null)}>No Guild</MenuItem>, ...(user?.guildList?.map((guild) => (
                        <MenuItem data-testid={guild.guildId} key={guild.guildId} onClick={() => handleSelectGuildContext(guild)}>{guild.guildName}</MenuItem>
                )) ?? [])]}

                </CustomNestedMenuItem>
                <IconMenuItem
                    onClick={handleLogout}
                    leftIcon={<ExitToApp/>}
                    label="Logout"
                    className="logout"
                />
            </Menu>
        </div>
    )
}

export default UserMenu
