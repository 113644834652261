import React, {useState} from 'react'
import CustomDialog from '../../../genericComponents/customDialog/CustomDialog'
import {InformationPackage} from '../../model/InformationPackage'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'

import {
    ValidationFormInformationPackageAccessForItem
} from '../../../service/serviceRequests/accessToInformationPackage/Forms/ValidationForm/ValidationFormInformationPackageAccessForItem'
import {
    ConfirmationInformationPackageAccessPopup
} from '../../../service/serviceRequests/accessToInformationPackage/Popup/ConfirmationPopup/ConfirmationInformationPackageAccessPopup'
import {selectCurrentUser} from '../../../guild/rtkGuildApi'
import {useSelector} from 'react-redux'
import {useCurrentGuildContext} from '../../../util/routing'
import {
    useCreateAccessToInformationPackageServiceRequestForItemMutation
} from '../../../service/serviceRequests/rtkServiceRequestApi'

function ItemServiceDialog({open, closeDialog, informationPackage, itemId}:
                               {
                                   open: boolean,
                                   closeDialog: () => void,
                                   informationPackage: InformationPackage,
                                   itemId: string
                               }) {
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()
    const user = useSelector(selectCurrentUser)
    const [createRequest, {
        isLoading,
        data: serviceRequest
    }] = useCreateAccessToInformationPackageServiceRequestForItemMutation()

    const [displayConfirmation, setDisplayConfirmation] = useState(false)
    const handleConfirmItemInformationPackageRequest = (itemId: string, informationPackage: InformationPackage) => {
        if (!user || !currentGuildContext) {
            dispatch(setSnackbarMessage('You have to select a guild in order to request access to information package.'))
            closeDialog()
            return
        }
        createRequest({
            guildId: currentGuildContext.guildId,
            itemId,
            informationPackageId: informationPackage.id,
            informationPackageName: informationPackage.label
        }).then(() => {
            setDisplayConfirmation(true)
        })
    }
    return (
        <CustomDialog
            className="quotation-dialog"
            open={open}
            title="Request Access to Information Package"
            onClose={closeDialog}
        >
            {!displayConfirmation &&
                <ValidationFormInformationPackageAccessForItem informationPackage={informationPackage} itemId={itemId}
                                                               handleConfirmItemInformationPackageRequest={handleConfirmItemInformationPackageRequest}
                                                               isLoading={isLoading}
                                                               closeDialog={closeDialog}/>}
            {displayConfirmation && <ConfirmationInformationPackageAccessPopup close={closeDialog}
                                                                               requestId={serviceRequest?.id}/>}
        </CustomDialog>
    )
}

export default ItemServiceDialog