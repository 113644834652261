import React from 'react'
import {formatDate, formatMilliseconds, formatMonth, formatUnixTime, fromNow} from './DateService'

export const DateDisplay = ({entryDate, className, time = true, from = false, long = false}: { entryDate: number[], className?: string | undefined, time?: boolean, from?: boolean, long?: boolean }) => {
    const dateToDisplay = formatMilliseconds([...entryDate])
    formatMonth(dateToDisplay)

    const timeToDisplay = formatDate(dateToDisplay)

    return (
        <span className={className}>
            {timeToDisplay && `${timeToDisplay} 
               ${time ? (formatUnixTime(dateToDisplay)) : ''} 
               ${from ? (' - ' + fromNow(dateToDisplay)) : ''}`}
        </span>
    )

}