import React from 'react'
import {Paper} from '@mui/material'
import {Email} from '@mui/icons-material'
import {useNavigate} from 'react-router-dom'

import './ServicesCataloguePage.scss'
import {ServiceCard} from '../serviceCatalogueCard/ServiceCard'
import {catalogueServices, Service} from '../model/Service'
import {setSnackbarMessage} from '../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../util/store'
import {selectCurrentUser} from '../../guild/rtkGuildApi'
import {useSelector} from 'react-redux'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'

const ServicesCataloguePage = () => {
    const navigate = useNavigate()
    const generatePath = useDatxPathGenerator()
    const dispatch = useTypedDispatch()

    const services = catalogueServices
    const [currentGuildContext] = useCurrentGuildContext()

    const user = useSelector(selectCurrentUser)

    function startServiceRequestForm(service: Service) {
        if (!user || !currentGuildContext) {
            dispatch(setSnackbarMessage('You have to select a guild in order to subscribe to a service.'))
        } else {
            navigate(generatePath(service.serviceFormPath))
        }
    }

    return (
        <div className="service-catalogue-page">
            <Paper sx={{boxShadow: 'none'}} className="paper-style-service-catalogue">
                <div className="header">
                    <div className="header-description">
                        <div className="header-title">
                            Service Catalogue
                        </div>
                    </div>
                    <div className="contact">
                        < Email className="contact-icon"/>
                        <a href="mailto:datx.contact@thalesgroup.com">Contact DatX</a>
                    </div>
                </div>
                <div className="content-service-catalogue">
                    {services.map((service) => {
                        return <ServiceCard
                            key={service.title}
                            service={service}
                            handleButtonClick={() => startServiceRequestForm(service)}
                        />
                    })}
                </div>
            </Paper>
        </div>
    )
}


export default ServicesCataloguePage
