import React from 'react'

import {DefinitionForDisplaySet} from '../definitionForDisplaySet/DefinitionForDisplaySet'
import {Link} from 'react-router-dom'
import {AttributeModel} from '../model/AttributeModel'
import {groupByDefinitionId, sortByDisplayOrder} from '../utils/sortedDefinitions'
import {useDatxPathGenerator} from '../../util/routing'
import {ROUTES} from '../../constants/routing'


export const LinkAttribute = ({attribute}: { attribute: AttributeModel }) => {
    const definitionsMap = groupByDefinitionId(attribute.value.composedByAttributes)
    const sortedDefinitions = sortByDisplayOrder([...definitionsMap.values()])
    const generatePath = useDatxPathGenerator()

    const targetItem = attribute?.value?.linkedItem
    return <div className="complex-attribute link-attribute">
        {targetItem && <Link to={generatePath(ROUTES.detailItem.path, {itemId: targetItem.id})} data-testid="test-relatedItem">
            {targetItem.partNumber}
            <span className="link-attribute-organization"> by {targetItem.definedByOrganization.label}</span>
        </Link>}

        {sortedDefinitions?.map(child => {
                if (!child) return <></>
                return <DefinitionForDisplaySet key={child?.attributeDefinitionId} definition={child}/>
            }
        )}
    </div>
}
