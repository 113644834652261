import './CreditManagement.scss'
import '../../../util/component/table.scss'
import {useGetGuildTransactionsQuery} from '../../rtkGuildApi'
import {useCurrentGuildContext} from '../../../util/routing'
import {GridColDef, GridValueGetterParams} from '@mui/x-data-grid'
import React, {useState} from 'react'
import {Transaction} from './models/Transaction'
import {GridRenderCellParams} from '@mui/x-data-grid/models/params/gridCellParams'
import {getTransactionTypeName} from './models/TransactionTypeEnum'
import {DateDisplay} from '../../../genericComponents/date/dateDisplay'
import {PageTitle} from '../../../genericComponents/typographie/PageTitle'
import {CREDITS} from '../../../constants/credits'
import {NumericFormat} from 'react-number-format'
import ExportCreditHistory from './ExportCreditHistory'
import {dateRangeMatch, equalsOperator, matchString} from '../../../genericComponents/grid/gridFilterOperators'
import DaTXDataGrid from '../../../genericComponents/grid/DaTXDataGrid'

const CreditManagement = () => {
    return (
        <>
            <div className="guild-credit-page">
                <div className="guild-credit-container">
                    <PageTitle id="guild-credit-title" title="Guild Credit Management" />
                    <div className="guild-credit-management">
                        <div className="guild-transaction-toolbar">
                            <span className="guild-transaction-label">Credit Transaction History</span>
                            <span className="guild-history-download">
                                <ExportCreditHistory />
                            </span>
                        </div>
                    </div>
                </div>
                <TransactionTable />
            </div>
        </>
    )
}

const TransactionTable = () => {
    const page = 0
    const pageSize = 100
    const [currentGuildContext] = useCurrentGuildContext()

    const {data: transactionPage} = useGetGuildTransactionsQuery(
        {
            pageNumber: page,
            pageSize: pageSize,
            guildId: currentGuildContext?.guildId
        },
        {
            skip: !currentGuildContext?.guildId || !currentGuildContext?.guildAdmin
        }
    )

    const formatIdLink = (row: GridRenderCellParams): React.ReactNode => {
        const value = row?.value
        if (!value) {
            return '-'
        }
        return (
            <span title={value} className="label-format">
                {value}
            </span>
        )
    }

    const getType = ({row}: {row: Transaction}) => {
        const value = getTransactionTypeName(row?.type)
        if (!value) {
            return '-'
        }
        return (
            <span title={value} className="label-format">
                {value}
            </span>
        )
    }

    const getTypeLabel = (params: GridValueGetterParams) => {
        return getTransactionTypeName(params.row.type)
    }

    const getEmail = ({row}: {row: Transaction}) => {
        const email = row?.user?.email
        if (!email) {
            return '-'
        }
        return (
            <span className="label-format" title={email}>
                {email}
            </span>
        )
    }

    const getEmailLabel = (params: GridValueGetterParams) => {
        return params.row?.user?.email || '-'
    }

    const getDate = (row: GridRenderCellParams) => {
        return <DateDisplay entryDate={row.row[row.field]} time={false} />
    }
    const getDateValue = (params: GridValueGetterParams) => {
        return params.row.createdDate || '-'
    }

    const getAmount = (row: GridRenderCellParams) => {
        const value = row?.value
        if (!value) {
            return '-'
        }
        const isNegative = value < 0
        return (
            <span className={`credit-change-amount ${isNegative ? 'negative-amount' : ''}`}>
                <NumericFormat
                    value={value}
                    displayType={'text'}
                    thousandSeparator={' '}
                    decimalSeparator="."
                    suffix={` ${CREDITS}`}
                    prefix={isNegative ? '- ' : '+ '}
                    allowNegative={false}
                />
            </span>
        )
    }
    const columns: GridColDef[] = [
        {
            field: 'serviceRequestId',
            headerName: 'Request ID',
            flex: 2,
            maxWidth: 184,
            disableColumnMenu: true,
            renderCell: formatIdLink
        },
        {
            field: 'type',
            headerName: 'Service',
            flex: 1,
            minWidth: 200,
            disableColumnMenu: false,
            renderCell: getType,
            valueGetter: getTypeLabel,
            filterOperators: [equalsOperator]
        },
        {
            field: 'user',
            headerName: 'Requestor',
            flex: 1,
            minWidth: 288,
            disableColumnMenu: false,
            renderCell: getEmail,
            valueGetter: getEmailLabel,
            filterOperators: [matchString]
        },
        {
            field: 'createdDate',
            headerName: 'Transaction Date',
            flex: 1,
            minWidth: 184,
            disableColumnMenu: false,
            renderCell: getDate,
            valueGetter: getDateValue,
            filterOperators: [dateRangeMatch]
        },
        {
            field: 'amount',
            headerName: 'Credits Change',
            flex: 1,
            maxWidth: 184,
            disableColumnMenu: true,
            renderCell: getAmount
        }
    ]
    return (
        <div className="guild-credit-grid-container">
            <DaTXDataGrid
                getRowId={(transaction: Transaction) => transaction?.id}
                rows={transactionPage?.content ?? []}
                columns={columns}
            ></DaTXDataGrid>
        </div>
    )
}

export default CreditManagement
