import React from 'react'
import {array, mixed, object} from 'yup'
import {useCreateDefaultServiceRequestMutation} from '../../../rtkServiceRequestApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../util/routing'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {ServiceEnum} from '../../../../model/ServiceEnum'
import {ServiceRequestFormTemplate} from '../../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ROUTES} from '../../../../../constants/routing'
import {UploadTemplateItemCreation} from '../../../newItems/common/UploadTemplateItemCreation'
import {getItemsFromFile} from '../../../readImportedFile'
import {PaidFormDescription} from '../Description/PaidFormDescription'
import {ReviewAndSubmitPartMatchingPaid} from '../ReviewAndSubmit/PartMatchingPaid'
import {PartMatchingItemForm} from './PartMatchingSelfServiceForm'

export const PartMatchingPaidForm = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const [createRequest] = useCreateDefaultServiceRequestMutation()

    const validationSchema = {
        file: mixed(),
        items: array().required().min(1)
    }

    const steps: ServiceRequestStep<PartMatchingItemForm>[] = [
        {
            name: 'Upload part matching paid form',
            component: <UploadTemplateItemCreation description={PaidFormDescription} handleFileUpload={handleFileUpload}/>,
            pageValidation: object<PartMatchingItemForm>({...validationSchema})
        },
        {
            name: 'Review & Submit',
            component: <ReviewAndSubmitPartMatchingPaid/>,
            pageValidation: object<PartMatchingItemForm>({...validationSchema})
        }
    ]

    async function handleFileUpload(tempFile: File) {
        return await getItemsFromFile(tempFile)
    }

    function handleCompleteUploadForm(itemCreation: PartMatchingItemForm): Promise<any> | undefined {
        if (!itemCreation.file) return
        if (!itemCreation.items) return

        return createRequest({
            service: ServiceEnum.PART_MATCHING,
            guildId: currentGuildContext?.guildId ?? '',
            file: itemCreation.file,
            numberOfItems: itemCreation.items.length
        })
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleCompleteUploadForm}
            initialValues={{
                file: undefined,
                items: []
            }}
            title="Item Matching - Consult DATX Expert"
            previousUrl={generatePath(ROUTES.partMatchingServiceChoice.path)}
        ></ServiceRequestFormTemplate>
    )
}
