import React from 'react'
import {UploadMonitoredBundleMonitoringForm} from './Forms/UploadForm/UploadMonitoredBundleMonitoringForm'
import {setSnackbarMessage} from '../../../genericComponents/commonSlice'
import {useTypedDispatch} from '../../../util/store'
import {ItemList} from '../../../itemList/model/ItemList'
import {MonitoredBundle} from '../../../item/model/MonitoredBundle'
import {selectCurrentUser} from '../../../guild/rtkGuildApi'
import {useSelector} from 'react-redux'
import {useCreateItemMonitoringServiceRequestMutation} from '../rtkServiceRequestApi'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../util/routing'
import {useNavigate} from 'react-router-dom'
import {ServiceRequestStep} from '../model/ServiceRequestStep'
import {ReviewMonitoredBundleMonitoringForm} from './Forms/ReviewStep/ReviewMonitoredBundleMonitoringForm'
import {mixed, object} from 'yup'
import {ROUTES} from '../../../constants/routing'
import {ServiceRequestFormTemplate} from '../genericComponents/formTemplate/ServiceRequestFormTemplate'

export interface RequestMonitoredBundleMonitoringInput {
    itemList?: ItemList,
    monitoredBundle?: MonitoredBundle,
}

const validationSchema = object<RequestMonitoredBundleMonitoringInput>({
    itemList: mixed().required(),
    monitoredBundle: mixed().required()
})

const MonitorMonitoredBundleForm = () => {
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()
    const [createRequest] = useCreateItemMonitoringServiceRequestMutation()
    const generatePath = useDatxPathGenerator()


    const steps: ServiceRequestStep<RequestMonitoredBundleMonitoringInput>[] = [{
        name: 'Request Item Monitoring',
        component: <UploadMonitoredBundleMonitoringForm/>,
        pageValidation: validationSchema,
        description: 'By filling this form you will be requesting to monitor your chosen list of components.'
    }, {
        name: 'Request Item Monitoring',
        component: <ReviewMonitoredBundleMonitoringForm/>,
        pageValidation: validationSchema
    }]


    const handleCompleteUploadForm = ({
                                          itemList,
                                          monitoredBundle
                                      }: RequestMonitoredBundleMonitoringInput): Promise<any> | undefined => {
        if (!monitoredBundle) {
            dispatch(setSnackbarMessage('You have to select an information package in order to monitor one.'))
            return
        }
        return createRequest({
            guildId: currentGuildContext?.guildId ?? '',
            itemListIds: [itemList?.id ?? ''],
            monitoredBundleId: monitoredBundle.id,
            monitoredBundleName: monitoredBundle.label
        })
    }

    return <ServiceRequestFormTemplate
        steps={steps}
        handleFormSubmit={handleCompleteUploadForm}
        initialValues={{
            itemList: undefined,
            monitoredBundle: undefined
        }}
        previousUrl={generatePath(ROUTES.servicesCatalogue.path)}
        title="Request Item Monitoring"
    />
}


export default MonitorMonitoredBundleForm
