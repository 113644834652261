import {Button, Typography} from '@mui/material'
import React, {ReactNode, useContext, useEffect, useState} from 'react'
import {DisplaySet} from './model/DisplaySet'
import './DisplaySetWrapper.scss'
import { DisplaySetContext } from './DisplaySetContext'

export const DisplaySetTabsWrapperComponent = ({displaySets, children, defaultTabName = 'Item Details'}: {
    displaySets: DisplaySet[] | undefined,
    children: ReactNode,
    defaultTabName?: string
}) => {
    const {selectedDisplaySet, setSelectedDisplaySet} = useContext(DisplaySetContext)

    return <div className="tabs-parent-component">
        <div className="displayset-tabs">
            {(displaySets && displaySets.length > 0) ? displaySets.map((displaySet) => (
                    <Button
                        key={displaySet.id + '-tab-button'}
                        data-testid={`ds-tab-${displaySet.id}`}
                        size="medium"
                        className={(displaySet.id === selectedDisplaySet?.id ? 'tab-button active' : 'tab-button')}
                        onClick={() => setSelectedDisplaySet(displaySet.id)}
                    ><Typography className="display-set-tab">{displaySet.label}</Typography></Button>
                )) :
                <Button
                    key={'default-display-set-tab-button'}
                    data-testid="ds-tab-itemdetails"
                    size="medium"
                    className="tab-button active"
                ><Typography className="display-set-tab">{defaultTabName}</Typography></Button>}
            {children}
        </div>
    </div>
}