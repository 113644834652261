import React from 'react'
import {ExportFormatEnum} from '../../../../model/ExportFormatEnum'
import {ExtractionFrequencyEnum} from '../../../../model/ExtractionFrequencyEnum'
import {ItemList} from '../../../../../itemList/model/ItemList'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../util/routing'
import {GroupOfAttributes} from '../../../../../item/model/Package'
import {useCreatePartExtractionServiceRequestMutation} from '../../../rtkServiceRequestApi'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {ServiceRequestFormTemplate} from '../../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ROUTES} from '../../../../../constants/routing'
import {DefineListAndDisplaySets} from './RecurringExtractionRequest/DefineListAndDisplaySets'
import {array, boolean, mixed, object, string} from 'yup'
import {ReviewAndSubmitPartExtraction} from '../../submit/ReviewAndSubmitPartExtraction'
import {useFormikContext} from 'formik'

export interface OneTimeExtractionRequestForm {
    itemList: ItemList | undefined
    file: File | undefined
    format: ExportFormatEnum | undefined
    extractionFrequency: ExtractionFrequencyEnum | undefined
    extractionPath: string | undefined
    selectedDisplaySets: GroupOfAttributes[] | undefined
    includesPrivateAttributes: boolean
}

export const OneTimeExtractionRequest = () => {
    useFormikContext<OneTimeExtractionRequestForm>()
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const [createService] = useCreatePartExtractionServiceRequestMutation()

    const defineListAndDisplaySets = {
        itemList: mixed().required('Item list is required'),
        selectedDisplaySets: array()
            .of(
                object().shape({
                    id: string().required()
                })
            )
            .required('At least one display set must be selected'),
        includesPrivateAttributes: boolean().optional()
    }

    const submitValidation = object<OneTimeExtractionRequestForm>({
        ...defineListAndDisplaySets
    })

    const steps: ServiceRequestStep<OneTimeExtractionRequestForm>[] = [
        {
            name: 'Define Extraction',
            component: <DefineListAndDisplaySets/>,
            pageValidation: object<OneTimeExtractionRequestForm>({...defineListAndDisplaySets}),
            description:
                'By filling this form, you will be requesting extraction of pre-defined attributes belonging to your chosen list of items in the selected format and extraction schedule.'
        },
        {
            name: 'Review and Submit',
            component: <ReviewAndSubmitPartExtraction/>,
            pageValidation: submitValidation,
            description:
                'By filling this form, you will be requesting extraction of pre-defined attributes belonging to your chosen list of items in the selected format and extraction schedule.'
        }
    ]

    const handleFormSubmit = (formResult: OneTimeExtractionRequestForm): Promise<any> | undefined => {
        if (!formResult.extractionFrequency) return
        if (!formResult.format) return
        if (!formResult.selectedDisplaySets) return

        return createService({
            guildId: currentGuildContext?.guildId ?? '',
            itemListIds: [formResult.itemList?.id ?? ''],
            displaySetIds: formResult.selectedDisplaySets.map((pack) => pack.id),
            includesPrivateAttributes: formResult.includesPrivateAttributes,
            format: formResult.format,
            frequency: formResult.extractionFrequency,
            extractionPath: formResult.extractionPath
        })
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleFormSubmit}
            initialValues={{
                itemList: undefined,
                file: undefined,
                format: ExportFormatEnum.XML,
                extractionFrequency: ExtractionFrequencyEnum.ONE_TIME,
                extractionPath: undefined,
                selectedDisplaySets: undefined,
                includesPrivateAttributes: false
            }}
            title="One-Time Extraction"
            previousUrl={generatePath(ROUTES.partExtractionServiceChoice.path)}
        ></ServiceRequestFormTemplate>
    )
}
