import React from 'react'
import {ServiceRequestStep} from '../../model/ServiceRequestStep'
import {array, mixed, object} from 'yup'
import {DefineUpdateStringUpdate} from './privateAttributeStringUpdate/DefineUpdateStringUpdate_step1'
import {DownloadTemplateStringUpdate} from './privateAttributeStringUpdate/DownloadTemplateStringUpdate_step2'
import {ReviewAndSubmitStringUpdate} from './privateAttributeStringUpdate/ReviewAndSubmitStringUpdate_step4'
import {ItemList} from '../../../../itemList/model/ItemList'
import {NewValueByItemDTO} from '../../../model/private/NewValueByItemDTO'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../util/routing'
import {
    useUpdateFreeTextServiceRequestMutation,
    useUpdatePrivateArtifactServiceRequestMutation
} from '../../rtkServiceRequestApi'
import {useTypedDispatch} from '../../../../util/store'
import {ROUTES} from '../../../../constants/routing'
import {useNavigate} from 'react-router-dom'
import {IdType} from '../../../../util/models/IdType'
import {PrivateAttributeDefinitionType} from '../model/PrivateAttributeDefinitionType'
import {UploadTemplateStringUpdate} from './privateAttributeStringUpdate/UploadTemplateStringUpdate_step3'
import {AttributeDefinitionTypeEnum} from '../../../../displaySet/model/AttributeModel'
import {UploadDocumentUpdate} from './privateAttributeDocument/UploadTemplateDocumentUpdate_step2'
import { ServiceRequestFormTemplate} from '../../genericComponents/formTemplate/ServiceRequestFormTemplate'


export interface AttributeStringUpdateForm {
    itemList: ItemList | undefined
    privateDefinition: PrivateAttributeDefinitionType | undefined
    file: File | undefined
    privateAttributesToUpdate: NewValueByItemDTO[] | undefined
    privateAttributesToDelete: IdType[] | undefined
}

export const PrivateAttributeUpdate = () => {
    const generatePath = useDatxPathGenerator()
    const navigate = useNavigate()
    const dispatch = useTypedDispatch()
    const [currentGuildContext] = useCurrentGuildContext()

    const [updateRequest] = useUpdateFreeTextServiceRequestMutation()
    const [updateArtifactRequest] = useUpdatePrivateArtifactServiceRequestMutation()

    // parent would have to know the state of Private attribute and Item List to determine next steps
    const [privateDefinition, setPrivateDefinition] = React.useState<PrivateAttributeDefinitionType | undefined>()
    const [itemList, setItemList] = React.useState<ItemList | undefined>()

    const defineUpdateStepValidation = {
        itemList: object().required(),
        privateDefinition: object().required()
    }

    const uploadFileStepValidation = {
        file: mixed().required()
    }

    const validationSchema = object<AttributeStringUpdateForm>({
        ...defineUpdateStepValidation,
        ...uploadFileStepValidation,
        privateAttributesToDelete: array(),
        privateAttributesToUpdate: array()
    })

    const defineUpdateStep = {
        name: 'Define Update',
        component: <DefineUpdateStringUpdate setPrivateDefinition={setPrivateDefinition}
                                             setItemList={setItemList}></DefineUpdateStringUpdate>,
        pageValidation: object<AttributeStringUpdateForm>({
            ...defineUpdateStepValidation
        })
    }

    const additionalStepsByDefinitionType = new Map<AttributeDefinitionTypeEnum, ServiceRequestStep<AttributeStringUpdateForm>[]>([
        [AttributeDefinitionTypeEnum.STRING, [
            {
                name: 'Download Template',
                component: <DownloadTemplateStringUpdate></DownloadTemplateStringUpdate>
                // for string update only: optional display
            },
            {
                name: 'Upload Attribute',
                component: <UploadTemplateStringUpdate></UploadTemplateStringUpdate>,
                pageValidation: object<AttributeStringUpdateForm>({
                    ...defineUpdateStepValidation,
                    ...uploadFileStepValidation
                })
            },
            {
                name: 'Review and Submit',
                component: <ReviewAndSubmitStringUpdate></ReviewAndSubmitStringUpdate>,
                pageValidation: validationSchema
            }
        ]],
        [AttributeDefinitionTypeEnum.ARTIFACT, [
            {
                name: 'Upload Document',
                component: <UploadDocumentUpdate></UploadDocumentUpdate>,
                pageValidation: object<AttributeStringUpdateForm>({
                    ...defineUpdateStepValidation,
                    ...uploadFileStepValidation
                })
            },
            {
                name: 'Review and Submit',
                component: <ReviewAndSubmitStringUpdate></ReviewAndSubmitStringUpdate>,
                pageValidation: validationSchema
            }
        ]]
    ])

    const getAdditionalStepsByDefinitionType = (type: AttributeDefinitionTypeEnum | undefined) => {
        if (!type) return []
        return additionalStepsByDefinitionType.get(type) ?? []
    }

    const updateSteps: ServiceRequestStep<AttributeStringUpdateForm>[] = [
        defineUpdateStep,
        ...getAdditionalStepsByDefinitionType(privateDefinition?.type.attributeDefinitionType)
    ]

    const handleFormSubmit = (formResult: AttributeStringUpdateForm):Promise<any> | undefined  => {
        if (!formResult.privateDefinition) return
        if (!formResult.file) return

        if (privateDefinition?.type.attributeDefinitionType === AttributeDefinitionTypeEnum.ARTIFACT) {
            return updateArtifactRequest({
                guildId: currentGuildContext?.guildId ?? '',
                deleteAttributesOnItems: formResult.privateAttributesToDelete ?? [],
                label: formResult.privateDefinition.name,
                itemList: formResult.itemList,
                attributeDefinitionId: formResult.privateDefinition.id,
                file: formResult.file
            })
        } else {
            return updateRequest({
                guildId: currentGuildContext?.guildId ?? '',
                newValueByItems: formResult.privateAttributesToUpdate ?? [],
                deleteAttributesOnItems: formResult.privateAttributesToDelete ?? [],
                label: formResult.privateDefinition.name,
                attributeDefinitionId: formResult.privateDefinition.id,
                file: formResult.file
            })
        }


    }

    return <ServiceRequestFormTemplate steps={updateSteps}
                                       handleFormSubmit={handleFormSubmit}
                                       initialValues={{
                                           itemList: undefined,
                                           privateDefinition: undefined,
                                           file: undefined,
                                           privateAttributesToDelete: undefined,
                                           privateAttributesToUpdate: undefined
                                       }}
                                       title="Update Existing Private Attribute"
                                       previousUrl={generatePath(ROUTES.managePrivateAttribute.path)}>
    </ServiceRequestFormTemplate>
}