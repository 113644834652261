import {useState} from 'react'
import {getServiceName} from '../../service/model/ServiceEnum'
import {useUpdateWorkflowConfigurationMutation} from '../rtkGuildApi'
import {WorkflowConfigurationStage} from './model/WorkflowConfigurationStage'
import CustomSwitch from '../../genericComponents/customSwitch/CustomSwitch'
import ManageAutoQuotationValidation from './ManageAutoQuotationValidation'
import {useTypedDispatch} from '../../util/store'
import {WorkflowConfiguration} from './model/WorkflowConfiguration'
import {setSnackbarMessage} from '../../genericComponents/commonSlice'

const ServiceWorkflowConfiguration = ({workflowConfiguration}: {workflowConfiguration: WorkflowConfiguration}) => {
    const dispatch = useTypedDispatch()

    const [updateWorkflowConfiguration] = useUpdateWorkflowConfigurationMutation()

    const [displayQuotationValidationForm, setDisplayQuotationValidationForm] = useState(
        workflowConfiguration.quotationAutoApproval
    )

    const [disabledSwitch, setDisabledSwitch] = useState<WorkflowConfigurationStage>()

    const handleServiceRequestToggle = (isAutoValidation: boolean, configuration: string) => {
        setDisabledSwitch(configuration as WorkflowConfigurationStage)
        handleUpdateWorkflowConfiguration({
            ...workflowConfiguration,
            serviceRequestAutoValidation: isAutoValidation
        })
    }
    const handleQuotationToggle = (isAutoValidation: boolean, configuration: string) => {
        setDisabledSwitch(configuration as WorkflowConfigurationStage)
        handleUpdateWorkflowConfiguration({
            ...workflowConfiguration,
            quotationAutoApproval: isAutoValidation,
            quotationAutoApprovalThreshold: null //by default, it is an auto approve quotation without credit limit
        })
    }

    const handleCreditThreshold = (newThreshold: number | null) => {
        setDisabledSwitch(WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION)
        handleUpdateWorkflowConfiguration({
            ...workflowConfiguration,
            quotationAutoApprovalThreshold: newThreshold
        })
    }

    const handleUpdateWorkflowConfiguration = (newWorkflowConfiguration: WorkflowConfiguration) => {
        updateWorkflowConfiguration({workflowConfiguration: newWorkflowConfiguration})
            .unwrap()
            .then(() => {
                setDisplayQuotationValidationForm(newWorkflowConfiguration.quotationAutoApproval)
                dispatch(setSnackbarMessage('Workflow configuration has been saved'))
            })
            .catch(() => {
                setDisplayQuotationValidationForm(false)
            })
            .finally(() => setDisabledSwitch(undefined))
    }

    return (
        <div className="service-workflow-setup">
            <div className="service-name" data-testid={`service-name ${getServiceName(workflowConfiguration.service)}`}>
                {getServiceName(workflowConfiguration.service)}
            </div>
            <div className="service-stage">
                <div>Auto-approve service requests</div>
                <div>
                    <CustomSwitch
                        disabled={disabledSwitch === WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}
                        value={WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}
                        isChecked={workflowConfiguration.serviceRequestAutoValidation}
                        toggleChecked={(e) => handleServiceRequestToggle(e.target.checked, e.target.value)}
                        dataTestid={`${WorkflowConfigurationStage.AUTO_APPROVE_SERVICE_REQUEST}-${getServiceName(workflowConfiguration.service)}`}
                    />
                </div>
            </div>
            <div className="service-stage">
                <div>Auto-approve quotations</div>
                <div>
                    <CustomSwitch
                        disabled={disabledSwitch === WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                        value={WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                        isChecked={workflowConfiguration.quotationAutoApproval}
                        toggleChecked={(e) => handleQuotationToggle(e.target.checked, e.target.value)}
                        dataTestid={`${WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}-${getServiceName(workflowConfiguration.service)}`}
                    />
                </div>
                {displayQuotationValidationForm && (
                    <ManageAutoQuotationValidation
                        dataTestid={`auto-quotation-selection-for-${getServiceName(workflowConfiguration.service)}`}
                        defaultThreshold={workflowConfiguration.quotationAutoApprovalThreshold}
                        handleThreshold={handleCreditThreshold}
                        disabled={disabledSwitch === WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
                    />
                )}
            </div>
        </div>
    )
}

export default ServiceWorkflowConfiguration
