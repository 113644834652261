import {AttributeModel} from '../../displaySet/model/AttributeModel'

export interface InformationPackage {
    id: string,
    displayOrder: number,
    label: string,
    billingType: BillingTypeEnum,
    attributes: AttributeModel[],
}

export enum BillingTypeEnum {
    FREE = 'FREE',
    TO_BUY = 'TO_BUY'
}

