import React, { ReactNode, useContext } from 'react'
import './ItemDetailsHeaderContent.scss'
import Tooltip from '@mui/material/Tooltip'
import {Link} from 'react-router-dom'
import {ItemTypeEnum} from '../../model/ItemType'
import {SimpleItem} from '../../model/SimpleItem'
import {ROUTES} from '../../../constants/routing'
import {useDatxPathGenerator} from '../../../util/routing'
import {OpenInNewRounded} from '@mui/icons-material'
import {Alert} from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'
import {ItemNatureIcon} from '../../itemNature/ItemNatureIcon'
import { CopyButton } from '../../../genericComponents/CopyButton'
import { IdType } from '../../../util/models/IdType'
import { ActionTag } from './ActionTag'
import { DisplaySetContext } from '../../../displaySet/DisplaySetContext'
import { hasDisplaySet } from '../../model/itemUtils'

const TECHNICAL_CLASSIFICATION_X = '6df3a887-532a-5fc4-a4bd-f37344957137'
const OTHER_IDENTIFICATIONS_DISPLAY_SET = '56617c1f-c125-4e8f-8578-d738b1c774b0'

const OtherIdentificationAction = () => {
    const {setSelectedDisplaySet} = useContext(DisplaySetContext)

    return (
        <ActionTag onClick={() => setSelectedDisplaySet(OTHER_IDENTIFICATIONS_DISPLAY_SET)}
            label="Other Identifications"
            iconUrl="/images/custom-icons/DoubleArrow.svg"
        />
    )
}

const ItemDetailsHeaderContent = ({selectedItemDetails}: {selectedItemDetails: SimpleItem}) => {
    const generatePath = useDatxPathGenerator()
    const hasOtherIdentifications = hasDisplaySet({item : selectedItemDetails, displaySetId : OTHER_IDENTIFICATIONS_DISPLAY_SET})
    
    return (
        <div className="item-header" data-testid="test-details-header">
            <div className="item-header-image">
                <ItemNatureIcon nature={selectedItemDetails.nature} />
            </div>
            <div>
                {selectedItemDetails.technical.id === TECHNICAL_CLASSIFICATION_X && (
                    <Alert className="x-classification-banner" icon={<InfoIcon className="icon" fontSize="small"/>}>
                        Minimal information is provided for this item. It is displayed here to validate its existence,
                        but no customer request has been made to complete them. <br />
                        Alternatively, the item may be of an older model, hence its technical specifications may not be
                        available.
                    </Alert>
                )}
                <div className="item-header-container">
                    <HeaderContent 
                        action={hasOtherIdentifications ? <OtherIdentificationAction /> : undefined}
                        label="Part Number" 
                        value={selectedItemDetails.partNumber} 
                        className="part-number" 
                    />
                   
                    {selectedItemDetails.type.label === ItemTypeEnum.MANUFACTURER ? (
                        <HeaderLinkContent
                            label="Defined By"
                            className="defined-by"
                            value={selectedItemDetails.definedByOrganization.label}
                            newTab={true}
                            path={generatePath(ROUTES.organizationDetails.path, {
                                organizationId: selectedItemDetails.definedByOrganization.id
                            })}
                        />
                    ) : (
                        <HeaderContent
                            label="Defined By"
                            className="defined-by"
                            value={selectedItemDetails.definedByOrganization.label}
                        />
                    )}
                    <HeaderContent
                        label="Codification System"
                        className="codification-system"
                        value={selectedItemDetails.definedByOrganization.codificationSystem}
                    />
                    <Tooltip title={selectedItemDetails.id ?? 'No value'} placement="top-start" className="datx-id">
                        {/*Surround with div to easily manage a custom component inside tooltip https://mui.com/material-ui/react-tooltip/#custom-child-element*/}
                        <div>
                            <HeaderContent label="Datx ID" value={`...${extractIdLastSegment(selectedItemDetails.id)}`} />
                            <CopyButton toCopy={selectedItemDetails.id} />
                        </div>
                    </Tooltip>

                    <HeaderContent label="Name" value={selectedItemDetails?.label} className="name" />

                    <HeaderLinkContent
                        label="Technical Classification"
                        className="technical-classification"
                        value={selectedItemDetails?.technical?.label}
                        path={generatePath(ROUTES.searchByClassificationNodeDescendants.path, {
                            classificationId: selectedItemDetails?.technical?.id
                        })}
                    />

                    <Tooltip
                        title={selectedItemDetails?.description ?? 'no description'}
                        placement="bottom-start"
                        className="description"
                    >
                        {/*Surround with div to easily manage a custom component inside tooltip https://mui.com/material-ui/react-tooltip/#custom-child-element*/}
                        <div>
                            <HeaderContent label="Item Description" value={selectedItemDetails?.description} />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    )
}

const extractIdLastSegment = (id: IdType) => id.slice(-12)

export const HeaderContent = ({
    label,
    value,
    action,
    className = ''
}: {
    label: string
    value: string | undefined,
    action?: ReactNode,
    className?: string
}) => {
    return (
        <div className={'item-header-content ' + className}>
            <span className="header-title">{label}{action}</span>
            <span className="header-value">{value}</span>
            
        </div>
    )
}

export const HeaderLinkContent = ({
    label,
    value,
    className = '',
    path,
    newTab = false
}: {
    label: string
    value: string | undefined
    className?: string
    path: string
    newTab?: boolean
}) => {
    return (
        <div className={'item-header-content ' + className} onClick={(event) => event.stopPropagation()}>
            <span className="header-title">{label}</span>
            {value === 'Not found' ? (
                <div className="header-container">{value}</div>
            ) : (
                <Link to={path} className="header-link header-value" target={newTab ? '_blank' : ''}>
                    <div className="header-container">
                        <span className="header-link" title={value}>
                            {value}
                        </span>
                        {newTab && <OpenInNewRounded className="header-icon" />}
                    </div>
                </Link>
            )}
        </div>
    )
}

export default ItemDetailsHeaderContent
