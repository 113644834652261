import React from 'react'
import '../../../styles/Forms.scss'
import './UploadCADModelCreationForm.scss'
import {FormControl, MenuItem, Select} from '@mui/material'
import {CADModelTypeEnum, getCadModelDisplayName} from '../../../../model/cadModelType/CADModelTypeEnum'
import {useFormikContext} from 'formik'
import {RequestCADModelInput} from '../../CADModelCreationForm'
import {KeyboardArrowDown} from '@mui/icons-material'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {SelectItemList} from '../../../genericComponents/SelectItemList'

export const UploadCADModelCreationForm = () => {
    const cadModelTypeOptions = Object.values(CADModelTypeEnum)
    const formik = useFormikContext<RequestCADModelInput>()

    return (
        <>
                <FormControl className="form-select">
                    <SelectItemList
                        selectedList={formik.values.itemList}
                        handleChange={formik.handleChange}
                        touched={formik.touched.itemList}
                        label="Item List"
                        errors={formik.errors.itemList}
                        required
                    />
                </FormControl>
                <FormControl className="form-select">
                    <Label id="itemlist-label" required>CAD Model Type</Label>

                    <Select
                        size="small"
                        data-testid="cad-model-type-dropdown-selector"
                        id="cad-model-type"
                        {...formik.getFieldProps('cadModelType')}
                        name="cadModelType"
                        value={formik.values.cadModelType || ''}
                        onChange={(event) => {
                            formik.handleChange(event)
                        }}
                        displayEmpty
                        IconComponent={KeyboardArrowDown}
                        renderValue={(selectedCadModelTypeEnum: CADModelTypeEnum) =>
                            getCadModelDisplayName[selectedCadModelTypeEnum] ||
                            <span className="placeholder">Select CAD Model Type</span>
                        }
                    >
                        {cadModelTypeOptions.map((type) => (
                            <MenuItem key={type} value={type} data-cy={`select-option-${type}`}>
                                {getCadModelDisplayName[type]}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
        </>
    )
}
