import {ChangeEvent} from 'react'
import {Switch} from '@mui/material'
import './CustomSwitch.scss'

const CustomSwitch = ({
    value,
    toggleChecked,
    isChecked = false,
    disabled = false,
    dataTestid = ''
}: {
    value: string
    toggleChecked: (e: ChangeEvent<HTMLInputElement>) => void
    isChecked?: boolean
    disabled: boolean
    dataTestid: string
}) => {
    const handleToggle = (e: ChangeEvent<HTMLInputElement>) => {
        toggleChecked(e)
    }

    return (
        <label className="custom-switch" data-testid={dataTestid}>
            <Switch value={value} checked={isChecked} onChange={handleToggle} disabled={disabled} />
            <span> {isChecked ? 'On' : 'Off'} </span>
        </label>
    )
}
export default CustomSwitch
