import React from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {useCurrentGuildContext, useIsGuildAccessible} from '../../util/routing'
import './GuildAccessCheck.scss'
import LoadingSpinner from '../../genericComponents/LoadingSpinner'
import NotFoundPage from '../../notFound/NotFoundPage'
import { useSelector } from 'react-redux'
import { isGetUserError } from '../../guild/rtkGuildApi'
import { ROUTES } from '../../constants/routing'

export const GuildAccessCheck = () => {
    const guildRetrievalError = useSelector(isGetUserError)
    const [hasAccessToTargetGuild, isInitialized] = useIsGuildAccessible()
    const navigate = useNavigate()
    
    if(guildRetrievalError){
        navigate(ROUTES.maintenance.publicPath || '')
    }

    return <>
        {
            !isInitialized ? <LoadingSpinner/> :
                hasAccessToTargetGuild ?
                    <div className="guild-access-container" data-testid="guildaccesscheck-app">
                        <Outlet/>
                    </div> : <NotFoundPage/>
        }
    </>
}
