import ISO6391 from 'iso-639-1'
import {Button, Link} from '@mui/material'
import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {AttributeModel} from '../model/AttributeModel'
import {useCurrentGuildContext} from '../../util/routing'
import {useTypedDispatch, useTypedSelector} from '../../util/store'
import {getItemDocument} from '../../item/itemAsyncActions'
import {CustomTooltip} from '../../genericComponents/customTooltip/CustomTooltip'
import {DateDisplay} from '../../genericComponents/date/dateDisplay'
import {Document, FileStatus} from '../model/AttributeValue'

export const ArtifactAttribute = ({attribute}: { attribute: AttributeModel }) => {
    const [currentGuildContext] = useCurrentGuildContext()
    const {itemId} = useParams()
    const dispatch = useTypedDispatch()
    const [currentDocument, setCurrentDocument] = useState('')
    const {itemDocument} = useTypedSelector((state) => state.item)

    useEffect(() => {
        if (!itemDocument.isLoading && currentDocument != '') {
            setCurrentDocument('')
        }
    }, [itemDocument, currentDocument])


    const getArtefactDocument = (document: Document)=>{
        setCurrentDocument(document.id)
        dispatch(getItemDocument({
            documentId: document.id,
            itemId,
            guildId: currentGuildContext?.guildId
        }))
    }

    const renderDocument = (document: Document) => {
        if (document.externalUrl) return <Button component={Link}
                                                 className="artifact-download"
                                                 href={document.externalUrl}
                                                 target="_blank"
                                                 data-testid="artefact-externalurl-download">{document.externalUrl}</Button>

        if (!document.file) return <></>
        return <Button className="artifact-download"
                       disabled={document.file.status !== FileStatus.VALID || currentDocument === document.id}
                       onClick={() => getArtefactDocument(document)}
                       data-testid="artefact-file-download" title={document?.file?.name}>
            <span>{document?.file?.name}</span>
        </Button>
    }

    const renderPublishedDate = (date: number[]) => {
        if(!date) return <span></span>
        return <div><CustomTooltip title="Published date" placement="top" arrow>
            <div className="artifact-published-date"><DateDisplay entryDate={date} time={false}/></div>
        </CustomTooltip></div>
    }

    const getLanguages = (document: Document) => {
        return document.languages.map(language => ISO6391.getName(language)).join(', ')
    }

    return <div key={attribute.id} className="artifact-attribute" data-testid="test-details-simple-attribute-value">
        {attribute.value.document && <>
            <div>{renderDocument(attribute.value.document)}</div>
            {renderPublishedDate(attribute.value.document.startOfValidity)}
            {attribute.value.document.languages.length > 0 &&
                <span>{getLanguages(attribute.value.document)}</span>}
        </>}
    </div>
}