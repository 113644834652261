import React from 'react'
import ItemNavigationMenu from '../headerGenericComponents/ItemNavigationMenu'
import {ItemNavigation} from '../headerGenericComponents/ItemNavigation'
import {ROUTES} from '../../constants/routing'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../util/routing'

const ServicesMenu = () => {
    const [currentGuildContext] = useCurrentGuildContext()
    const generatePath = useDatxPathGenerator()

    const data: ItemNavigation[] = [
        {
            label: 'Service Catalogue',
            objId: '1',
            url: generatePath(ROUTES.servicesCatalogue.path),
            dataTestId: 'service-catalog'
        },
        {
            label: 'My Service Requests',
            objId: '2',
            url: generatePath(ROUTES.userServiceRequests.path),
            disabled: !currentGuildContext,
            dataTestId: 'my-service-requests'
        },
        {
            label: 'My Service Subscriptions',
            objId: '3',
            url: generatePath(ROUTES.userServiceSubscriptions.path),
            disabled: !currentGuildContext,
            dataTestId: 'my-service-subscriptions'
        },
    ]


    return (
        <ItemNavigationMenu
            label="Services"
            itemList={data}
            subRoute="/services"
        />
    )
}

export default ServicesMenu
