export enum ServiceEnum {
    PART_MATCHING = 'PART_MATCHING',
    PART_MATCHING_SELF_SERVICE = 'PART_MATCHING_SELF_SERVICE',
    PART_MATCHING_CONSULT_DATX_EXPERT = 'PART_MATCHING_CONSULT_DATX_EXPERT',
    NEW_PARTS = 'NEW_PARTS',
    NEW_MANUFACTURER_ITEMS = 'NEW_MANUFACTURER_ITEMS',
    NEW_COMPANY_ITEMS = 'NEW_COMPANY_ITEMS',
    EXTRACTION = 'EXTRACTION',
    ONE_TIME_EXTRACTION = 'ONE_TIME_EXTRACTION',
    RECURRING_EXTRACTION = 'RECURRING_EXTRACTION',
    CREATE_PRIVATE_ATTRIBUTES = 'CREATE_PRIVATE_ATTRIBUTES',
    UPDATE_PRIVATE_ATTRIBUTES = 'UPDATE_PRIVATE_ATTRIBUTES',
    MANAGE_PRIVATE_ATTRIBUTES = 'MANAGE_PRIVATE_ATTRIBUTES',
    CAD_MODEL = 'CAD_MODEL',
    CONSULTING_AND_EXPERTISE = 'CONSULTING_AND_EXPERTISE',
    SUBSCRIBE_INFORMATION_PACKAGE = 'SUBSCRIBE_INFORMATION_PACKAGE',
    ITEM_MONITORING = 'ITEM_MONITORING',
    MARKETPLACE = 'MARKETPLACE',
    REQUEST_FOR_SUPPORT = 'REQUEST_FOR_SUPPORT',
    PART_REQUEST = 'PART_REQUEST'
}
const serviceNames = {
    [ServiceEnum.PART_MATCHING]: {
        title: 'Item Matching'
    },
    [ServiceEnum.PART_MATCHING_SELF_SERVICE]: {
        title: 'Self-Service'
    },
    [ServiceEnum.PART_MATCHING_CONSULT_DATX_EXPERT]: {
        title: 'Consult DATX Expert'
    },
    [ServiceEnum.PART_MATCHING]: {
        title: 'Item Matching'
    },
    [ServiceEnum.NEW_PARTS]: {
        title: 'New Items'
    },
    [ServiceEnum.NEW_MANUFACTURER_ITEMS]: {
        title: 'Create Manufacturer Items'
    },
    [ServiceEnum.NEW_COMPANY_ITEMS]: {
        title: 'Create Company Items'
    },
    [ServiceEnum.EXTRACTION]: {
        title: 'Item Extractions'
    },
    [ServiceEnum.ONE_TIME_EXTRACTION]: {
        title: 'One-Time Extraction'
    },
    [ServiceEnum.RECURRING_EXTRACTION]: {
        title: 'Recurring Extraction'
    },
    [ServiceEnum.UPDATE_PRIVATE_ATTRIBUTES]: {
        title: 'Manage Private Attributes Update'
    },
    [ServiceEnum.CREATE_PRIVATE_ATTRIBUTES]: {
        title: 'Manage Private Attributes Creation'
    },
    [ServiceEnum.MANAGE_PRIVATE_ATTRIBUTES]: {
        title: 'Manage Private Attributes'
    },
    [ServiceEnum.CAD_MODEL]: {
        title: 'Item Modelling'
    },
    [ServiceEnum.CONSULTING_AND_EXPERTISE]: {
        title: 'Consulting & Expertise'
    },
    [ServiceEnum.SUBSCRIBE_INFORMATION_PACKAGE]: {
        title: 'Access to Item Data'
    },
    [ServiceEnum.ITEM_MONITORING]: {
        title: 'Item Monitoring'
    },
    [ServiceEnum.MARKETPLACE]: {
        title: 'Market Place'
    },
    [ServiceEnum.REQUEST_FOR_SUPPORT]: {
        title: 'Support'
    }
}

export const isRecurringExtraction = (value: string): boolean => {
    return ServiceEnum.RECURRING_EXTRACTION.valueOf() === value
}

export const isExtraction = (value: string): boolean => {
    return ServiceEnum.ONE_TIME_EXTRACTION.valueOf() === value || isRecurringExtraction(value)
}

export const getServiceName = (value: ServiceEnum | string): string => {
    return (serviceNames as any)[value]?.title ?? ''
}
