import {configureStore} from '@reduxjs/toolkit'
import headerReducer from '../header/headerSlice'
import commonReducer from '../genericComponents/commonSlice'
import itemReducer from '../item/itemSlice'

import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {guildAPI} from '../guild/rtkGuildApi'
import {rtkQueryErrorLogger} from '../rtkQueryUtils'
import {notificationAPI} from '../header/menuComponents/Notification/rtkNotificationApi'
import {organizationAPI} from '../organization/rtkOrganizationApi'
import {searchAPI} from '../search/rtkSearchApi'
import {extractAPI} from '../extract/rtkExtractApi'
import {itemListAPI} from '../itemList/rtkItemListApi'

const store = configureStore({
    reducer: {
        header: headerReducer,
        common: commonReducer,
        item: itemReducer,
        [guildAPI.reducerPath]: guildAPI.reducer,
        [notificationAPI.reducerPath]: notificationAPI.reducer,
        [organizationAPI.reducerPath]: organizationAPI.reducer,
        [searchAPI.reducerPath]: searchAPI.reducer,
        [extractAPI.reducerPath]: extractAPI.reducer,
        [itemListAPI.reducerPath]: itemListAPI.reducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware()
        .concat(guildAPI.middleware)
        .concat(notificationAPI.middleware)
        .concat(searchAPI.middleware)
        .concat(organizationAPI.middleware)
        .concat(extractAPI.middleware)
        .concat(itemListAPI.middleware)
        .concat(rtkQueryErrorLogger),
})

export type RootStateType = ReturnType<typeof store.getState>
export type AppDispatchType = typeof store.dispatch

export const useTypedDispatch = () => useDispatch<AppDispatchType>()
export const useTypedSelector: TypedUseSelectorHook<RootStateType> = useSelector

export default store
