import React from 'react'
import {useFormikContext} from 'formik'
import {AttributeStringCreationForm} from './PrivateAttributeStringCreation'
import * as FileSaver from 'file-saver'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {Link} from '@mui/material'

export const ReviewAndSubmitStringCreation = () => {
    const formik = useFormikContext<AttributeStringCreationForm>()

    const getFile = () => {
        const file = formik?.values?.file
        if (!file) return
        FileSaver.saveAs(file, file.name)
    }

    return <ServiceRequestStep>
        <Description>Review the parameters of your selection and submit.</Description>
        <div className="service-request-form-summary">
            <Label className="form-summary-label">New Private Attribute</Label>
            <span className="form-summary-value">{formik?.values?.attributeName ?? '-'}</span>

            <Label className="form-summary-label">Attribute Type</Label>
            <span className="form-summary-value">String</span>

            <Label className="form-summary-label">Item List</Label>
            <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>

            <Label className="form-summary-label">Items to update</Label>
            <span className="form-summary-value">{formik?.values?.privateAttributes?.length ?? '-'}</span>

            <Label className="form-summary-label">Uploaded Template</Label>
            <Link onClick={getFile}
                  className="form-summary-value link"
                  data-testid="download-template">{formik?.values?.file?.name ?? '-'}</Link>
        </div>
    </ServiceRequestStep>
}