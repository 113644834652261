import React, {ChangeEvent, SyntheticEvent} from 'react'
import {FormControl} from '@mui/material'
import UploadCommonLoadingButton from '../../../genericComponents/UploadCommonLoadingButton'
import {useFormikContext} from 'formik'
import {NewValueByItemDTO} from '../../../../model/private/NewValueByItemDTO'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import { setSnackbarMessage } from '../../../../../genericComponents/commonSlice'
import { useDispatch } from 'react-redux'
import { AttributeStringUpdateForm } from '../PrivateAttributeUpdate'
import { generateTimestamp } from '../../../../../genericComponents/date/DateService'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'

export const UploadDocumentUpdate = () => {
    const formik = useFormikContext<AttributeStringUpdateForm>()
    const dispatch = useDispatch()

    const setFile = (file: File | undefined) => {
        formik.setFieldValue('file', file)
    }

    const setPrivateAttributes = (privateAttributes: NewValueByItemDTO[] | undefined) => {
        if (!privateAttributes || privateAttributes.length <= 0) return

        const privateAttributesToUpdate = privateAttributes.filter(
            (attribute) =>
                attribute.value !== null && attribute.value !== '' && attribute.value !== undefined && attribute.itemId
        )
        formik.setFieldValue('privateAttributes', privateAttributesToUpdate)
    }

    const unselectFile = (event: SyntheticEvent) => {
        setPrivateAttributes(undefined)
        setFile(undefined)
        event.preventDefault()
    }

    const renameFileWithTimestamp = (originalFile: File) => {
        const lastDot = originalFile.name.lastIndexOf('.')
        const fileName = originalFile.name.substring(0, lastDot)
        const ext = originalFile.name.substring(lastDot)

        const newFileName = fileName + '-' + generateTimestamp() + ext

        return new File([originalFile], newFileName, {
            type: originalFile.type,
            lastModified: originalFile.lastModified
        })
    }

    const parseFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return
        if (event.target?.files[0].size > 100000000) {
            dispatch(setSnackbarMessage('File selected is larger than 100 MB limit'))
            return
        }

        const tempFile = event.target.files[0]
        const tempFileWithTimestamp = renameFileWithTimestamp(tempFile)
        setFile(tempFileWithTimestamp)
    }

    return (
        <ServiceRequestStep>
            <Description className="service-request-step-description">
                By submitting the new document, the existing document will be updated for the relevant items.<br/>
                Please note that, file name will be time stamped upon successful document upload.
            </Description>

            <FormControl className="download-upload-template-container">
                <Label className="file-download-upload-label" htmlFor="upload-file">
                    Upload Document:
                </Label>
                <UploadCommonLoadingButton
                    className="upload-template-button"
                    selectedFileName={formik?.values?.file?.name ?? ''}
                    unselectFile={unselectFile}
                    parseFile={parseFile}
                    acceptedFileType=".pdf, .txt, .ppt, .pptx, .doc, .docx, .xls, .xlsx"
                />
            </FormControl>
        </ServiceRequestStep>
    )
}
