import {Button, Radio, RadioGroup} from '@mui/material'
import {AutoQuotationValidation, WorkflowConfigurationStage} from './model/WorkflowConfigurationStage'
import {useState} from 'react'
import './ManageAutoQuotationValidation.scss'
import {useFormik} from 'formik'
import {number, object} from 'yup'
import {WarningAmberOutlined} from '@mui/icons-material'
import {NumericFormat} from 'react-number-format'

const ManageAutoQuotationValidation = ({
    defaultThreshold,
    handleThreshold,
    disabled = false,
    dataTestid = ''
}: {
    defaultThreshold: number | null
    handleThreshold: (threshold: number | null) => void
    disabled: boolean
    dataTestid?: string
}) => {
    const [displayButton, setDisplayButton] = useState(false)

    const [selectedQuotationValidationType, setSelectedQuotationValidationType] = useState(
        defaultThreshold == null ? AutoQuotationValidation.ALWAYS : AutoQuotationValidation.WITH_CREDIT_THRESHOLD
    )

    const formik = useFormik({
        initialValues: {
            threshold: defaultThreshold
        },
        validationSchema: object({
            threshold: number()
                .required()
                .max(10000000, 'quotation approval threshold should be lower than 10000000')
                .min(0, 'quotation approval threshold should not be negative')
        }),
        onSubmit: (values) => {
            if (values.threshold !== defaultThreshold) {
                handleThreshold(values.threshold)
                setDisplayButton(false)
            }
        }
    })

    const handleChange = (e: any) => {
        const selectedOption = e.target.value
        //update the workflow configuration when changing to the "always auto-approve" quotation from the quotation validation with credit threshold
        if (AutoQuotationValidation.ALWAYS === selectedOption) {
            formik.setFieldValue('threshold', null)
            handleThreshold(null)
        }
        setSelectedQuotationValidationType(selectedOption)
    }

    const isAlwaysAutoApproval = () => selectedQuotationValidationType === AutoQuotationValidation.ALWAYS
    const isAutoApprovalWithThreshold = () =>
        selectedQuotationValidationType === AutoQuotationValidation.WITH_CREDIT_THRESHOLD

    const isValidCreditThreshold = (threshold: number | null) => {
        return isAutoApprovalWithThreshold() && threshold !== null && +threshold >= 0
    }

    const inputStyle = (threshold: number | null): string => {
        if (isAlwaysAutoApproval()) return 'disabled'
        if (!isValidCreditThreshold(threshold)) return 'error'
        return 'active'
    }

    const showErrorMessage = () => {
        if (isAlwaysAutoApproval()) return
        if (!isValidCreditThreshold(formik.values.threshold))
            return (
                <div className="threshold-error">
                    <WarningAmberOutlined />
                    This field cannot be empty
                </div>
            )
        return
    }

    return (
        <RadioGroup
            className={`auto-quotation-selection ${disabled ? 'disabled' : ''}`}
            value={selectedQuotationValidationType}
            onChange={handleChange}
            name={WorkflowConfigurationStage.AUTO_APPROVE_QUOTATION}
            data-testid={dataTestid}
        >
            <div className="option">
                <Radio id="auto-approval-quotation-always" className="radio" value={AutoQuotationValidation.ALWAYS} />
                <label htmlFor="auto-approval-quotation-always">Always</label>
            </div>

            <div className="option">
                <Radio
                    id="auto-approval-quotation-with-credit-limit"
                    className="radio"
                    value={AutoQuotationValidation.WITH_CREDIT_THRESHOLD}
                />
                <div>
                    <label
                        htmlFor="auto-approval-quotation-with-credit-limit"
                        className={isAlwaysAutoApproval() ? 'disabled' : ''}
                    >
                        When quotation is lower or equal than:
                    </label>
                    <form onSubmit={formik.handleSubmit} className="auto-approval-quotation-with-threshold">
                        <div className="threshold-input-container">
                            <NumericFormat
                                key={selectedQuotationValidationType}
                                autoComplete="off"
                                isAllowed={({formattedValue, floatValue}) =>
                                    formattedValue === '' ||
                                    (floatValue !== undefined && floatValue <= Number.MAX_VALUE && floatValue >= 0)
                                }
                                disabled={disabled || isAlwaysAutoApproval()}
                                placeholder="Number of Credits"
                                decimalSeparator="."
                                required
                                value={formik.values.threshold}
                                onValueChange={({floatValue}) => {
                                    formik.setFieldValue('threshold', floatValue)
                                    setDisplayButton(true)
                                }}
                                name="threshold"
                                className={`threshold-input ${inputStyle(formik.values.threshold)}`}
                                data-testid="threshold-input"
                            />
                            {showErrorMessage()}
                        </div>
                        {isValidCreditThreshold(formik.values.threshold) && displayButton && (
                            <Button
                                variant="contained"
                                type="submit"
                                disabled={isAlwaysAutoApproval()}
                                data-testid="quotation-submit-button"
                            >
                                Save
                            </Button>
                        )}
                    </form>
                </div>
            </div>
        </RadioGroup>
    )
}

export default ManageAutoQuotationValidation
