import React from 'react'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {array, mixed, object, string} from 'yup'
import {DefineCreationStringCreation} from './DefineCreationStringCreation_step1'
import {DownloadTemplateStringCreation} from './DownloadTemplateStringCreation_step2'
import {UploadTemplateStringCreation} from './UploadTemplateStringCreation_step3'
import {ReviewAndSubmitStringCreation} from './ReviewAndSubmitStringCreation_step4'
import {ItemList} from '../../../../../itemList/model/ItemList'
import {NewValueByItemDTO} from '../../../../model/private/NewValueByItemDTO'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../util/routing'
import {useCreateFreeTextServiceRequestMutation} from '../../../rtkServiceRequestApi'
import {ROUTES} from '../../../../../constants/routing'
import {ServiceRequestFormTemplate} from '../../../genericComponents/formTemplate/ServiceRequestFormTemplate'

export interface AttributeStringCreationForm {
    itemList: ItemList | undefined
    attributeName: string | undefined
    file: File | undefined,
    privateAttributes: NewValueByItemDTO[] | undefined
}

export const PrivateAttributeStringCreation = () => {
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()

    const [createRequest] = useCreateFreeTextServiceRequestMutation()

    const defineCreationStepValidation = {
        itemList: object().required(),
        attributeName: string().required().max(100)
    }

    const uploadTemplateStepValidation = {
        file: mixed().required()
    }

    const validationSchema = object<AttributeStringCreationForm>({
        ...defineCreationStepValidation,
        ...uploadTemplateStepValidation,
        privateAttributes: array().required().min(1)
    })

    const steps: ServiceRequestStep<AttributeStringCreationForm>[] = [{
        name: 'Define Creation',
        component: <DefineCreationStringCreation
            itemListSelectLabel="Create new private attribute for items in this list"></DefineCreationStringCreation>,
        pageValidation: object<AttributeStringCreationForm>({
            ...defineCreationStepValidation
        })
    }, {
        name: 'Download Template',
        component: <DownloadTemplateStringCreation></DownloadTemplateStringCreation>
    }, {
        name: 'Upload Template',
        component: <UploadTemplateStringCreation></UploadTemplateStringCreation>,
        pageValidation: object<AttributeStringCreationForm>({
            ...defineCreationStepValidation,
            ...uploadTemplateStepValidation,
        })
    }, {
        name: 'Review and Submit',
        component: <ReviewAndSubmitStringCreation></ReviewAndSubmitStringCreation>,
        pageValidation: validationSchema
    }]

    const handleFormSubmit = (formResult: AttributeStringCreationForm): Promise<any> | undefined => {
        if (!formResult.privateAttributes) return
        if (!formResult.attributeName) return
        if (!formResult.file) return

        return createRequest({
            guildId: currentGuildContext?.guildId ?? '',
            newValueByItems: formResult.privateAttributes,
            label: formResult.attributeName,
            file: formResult.file
        })
    }

    return <ServiceRequestFormTemplate steps={steps}
                                       withStepper={true}
                                       handleFormSubmit={handleFormSubmit}
                                       initialValues={{
                                           itemList: undefined,
                                           attributeName: undefined,
                                           file: undefined,
                                           privateAttributes: undefined
                                       }}
                                       title="Create New Private Attribute"
                                       previousUrl={generatePath(ROUTES.selectTypeCreationPrivateAttribute.path)}>
    </ServiceRequestFormTemplate>
}