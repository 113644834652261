import {AttributeModel} from '../model/AttributeModel'
import React from 'react'
import {DateDisplay} from '../../genericComponents/date/dateDisplay'


export const DateAttribute = ({attribute}: { attribute: AttributeModel }) => {
    const dateAttribute = attribute?.value?.value
    if (!dateAttribute) return <></>

    const dateArray = dateAttribute.split('-').map(each => parseInt(each))
    return <span className="date-attribute">
        <DateDisplay entryDate={dateArray} time={false}/>
    </span>
}