import React from 'react'
import { ServiceRequestStep } from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import '../../../styles/Forms.scss'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {useFormikContext} from 'formik'
import {RequestCADModelInput} from '../../CADModelCreationForm'
import { Label } from '../../../../../genericComponents/typographie/Label'

export const ReviewCADModelCreationForm = () => {

    const formik = useFormikContext<RequestCADModelInput>()

    return (
        <ServiceRequestStep>
            <Description>Review the perimeters of your selection and submit.</Description>

            <div data-testid="cad-model-creation-summary" className="service-request-form-summary">
                <Label className="form-summary-label">Item List</Label>
                <span className="form-summary-value">{formik?.values?.itemList?.name ?? '-'}</span>
                <Label className="form-summary-label">CAD Model Type</Label>
                <span className="form-summary-value">{formik?.values?.cadModelType ?? '-'}</span>
            </div>
        </ServiceRequestStep>
    )
}
