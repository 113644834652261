import React from 'react'
import {useSelector} from 'react-redux'
import {array, mixed, object} from 'yup'
import {useNavigate} from 'react-router-dom'
import {useTypedDispatch} from '../../../../../util/store'
import {useCurrentGuildContext, useDatxPathGenerator} from '../../../../../util/routing'
import {ServiceRequestStep} from '../../../model/ServiceRequestStep'
import {ServiceRequestFormTemplate} from '../../../genericComponents/formTemplate/ServiceRequestFormTemplate'
import {ROUTES} from '../../../../../constants/routing'
import {selectCurrentUser} from '../../../../../guild/rtkGuildApi'
import {UploadTemplateItemCreation} from '../../../newItems/common/UploadTemplateItemCreation'
import {getItemsFromPartMatchingFile} from '../../../readImportedFile'
import {ReviewAndSubmitPartMatchingPaid} from '../ReviewAndSubmit/PartMatchingPaid'
import {MatchingSearch, PartNumberSearchDTO} from '../../../../../search/model/PartNumberSearchDTO'
import {SearchItem} from '../../../../../search/model/SearchItem'
import {useLazyGetItemMatchingQuery} from '../../../../../search/rtkSearchApi'
import * as XLSX from 'xlsx'
import * as FileSaver from 'file-saver'
import {SelfServiceDescription} from '../Description/SelfServiceDescription'
import {ItemMatchingInputDTO, NewItemInputDTO} from '../../../../model/NewItemInput'
import {ConfirmationStepSelfService} from '../ConfirmationStep/ConfirmationStepSelfService'

export interface PartMatchingItemForm {
    file: File | undefined
    items: NewItemInputDTO[] | ItemMatchingInputDTO[]
}

interface ItemMatchingOutput {
    'Line #': string
    PARTNUMBER_IN: string
    MANUFACTURER_IN: string
    PARTNUMBER_OUT: string
    MANUFACTURER_OUT: string
    DATXID: string
    'TECHNICAL CLASSIFICATION': string
    DESCRIPTION: string
}

interface ItemMatchingResultDTO {
    partNumber: string
    manufacturer: string
    itemsByPartNumber: SearchItem[]
    tooManyItems: boolean
}

export const PartMatchingSelfServiceForm = () => {
    const dispatch = useTypedDispatch()
    const generatePath = useDatxPathGenerator()
    const [currentGuildContext] = useCurrentGuildContext()
    const navigate = useNavigate()
    const user = useSelector(selectCurrentUser)
    const [trigger] = useLazyGetItemMatchingQuery()

    const validationSchema = {
        file: mixed(),
        items: array().required().min(1)
    }

    const steps: ServiceRequestStep<PartMatchingItemForm>[] = [
        {
            name: 'Upload part matching self service form',
            component: <UploadTemplateItemCreation description={SelfServiceDescription} handleFileUpload={handleFileUpload}/>,
            pageValidation: object<PartMatchingItemForm>({...validationSchema})
        },
        {
            name: 'Review and Submit',
            component: <ReviewAndSubmitPartMatchingPaid/>,
            pageValidation: object<PartMatchingItemForm>({...validationSchema})
        }
    ]
    const handleCompleteUploadForm = (itemCreation: PartMatchingItemForm): Promise<any> | undefined => {
        if (!itemCreation.file) return
        if (!itemCreation.items) return

        const matchingSearch: MatchingSearch[] = ((itemCreation?.items as ItemMatchingInputDTO[]) || [])
            .filter((item) => !!item.partNumberIn)
            .map((item) => ({
                partNumber: item.partNumberIn,
                manufacturer: item.manufacturerIn
            }))

        const partNumberSearchDTO: PartNumberSearchDTO = {
            matchingSearch,
            guildId: currentGuildContext?.guildId ?? ''
        }

        return trigger(partNumberSearchDTO).then((result) => {
            if (result.isSuccess) {
                formatItemMatchOutput(result?.data)
            }
            return result
        })
    }

    const formatItemMatchOutput = (itemMatching: ItemMatchingResultDTO[]) => {
        let previousPartNumber = ''
        let lineCounter = 0

        const excelData = itemMatching.reduce(
            (matchings: ItemMatchingOutput[], itemMatchingResult: ItemMatchingResultDTO) => {
                const {itemsByPartNumber, partNumber, manufacturer, tooManyItems} = itemMatchingResult
                const noItemMatch = !itemsByPartNumber?.length
                const inputItemAttributes = {
                    PARTNUMBER_IN: partNumber,
                    MANUFACTURER_IN: manufacturer
                }

                if (partNumber !== previousPartNumber) {
                    lineCounter = lineCounter + 1
                }

                if (noItemMatch) {
                    matchings.push({
                        'Line #': lineCounter.toString(),
                        ...inputItemAttributes,
                        PARTNUMBER_OUT: tooManyItems ? 'Too many items. please refine your search' : 'No Result found',
                        MANUFACTURER_OUT: '-',
                        DATXID: '-',
                        'TECHNICAL CLASSIFICATION': '-',
                        DESCRIPTION: '-'
                    })
                } else {
                    itemsByPartNumber.forEach((item) => {
                        matchings.push({
                            'Line #': lineCounter.toString(),
                            ...inputItemAttributes,
                            PARTNUMBER_OUT: item.partNumber,
                            MANUFACTURER_OUT: item.definedByOrganization.label,
                            DATXID: item.id,
                            'TECHNICAL CLASSIFICATION': item.technical.label,
                            DESCRIPTION: item.description
                        })
                    })
                }
                previousPartNumber = partNumber

                return matchings
            },
            []
        )

        downloadExcel(excelData)
    }

    const downloadExcel = (excelData: ItemMatchingOutput[]) => {
        const fileName = 'datx_matching_self_service'
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
        const fileExtension = '.xlsx'
        // Init worksheet
        const ws = XLSX.utils.json_to_sheet(excelData)

        XLSX.utils.sheet_add_json(ws, excelData)

        // Write to workbook
        const wb = {Sheets: {data: ws}, SheetNames: ['data']}
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
        const data = new Blob([excelBuffer], {type: fileType})
        FileSaver.saveAs(data, fileName + fileExtension)
    }

    async function handleFileUpload(tempFile: File) {
        return await getItemsFromPartMatchingFile(tempFile)
    }

    return (
        <ServiceRequestFormTemplate
            steps={steps}
            handleFormSubmit={handleCompleteUploadForm}
            initialValues={{
                file: undefined,
                items: []
            }}
            title="Item Matching - Self-Service"
            previousUrl={generatePath(ROUTES.partMatchingServiceChoice.path)}
            optionalConfirmationStep={<ConfirmationStepSelfService/>}
        ></ServiceRequestFormTemplate>
    )
}
export default PartMatchingSelfServiceForm
