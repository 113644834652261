import React from 'react'

import {DefinitionForDisplaySet} from '../definitionForDisplaySet/DefinitionForDisplaySet'
import {AttributeModel} from '../model/AttributeModel'
import {groupByDefinitionId, sortByDisplayOrder} from '../utils/sortedDefinitions'


export const ComplexAttribute = ({attribute}: { attribute: AttributeModel }) => {
    const definitionsMap = groupByDefinitionId(attribute.value.composedByAttributes)
    const sortedDefinitions = sortByDisplayOrder([...definitionsMap.values()])

    return <div className="complex-attribute">
        {sortedDefinitions?.map(child => {
                if (!child) return <></>
                return <DefinitionForDisplaySet key={child?.attributeDefinitionId} definition={child}/>
            }
        )}
    </div>
}
