import {PrivateAttributeManagement} from './PrivateAttributeManagement'
import * as XLSX from 'xlsx'
import {WorkSheet} from 'xlsx'
import * as FileSaver from 'file-saver'

export const generateTemplateFileName = (listName?: string | null, attributeName?: string) => {
    return listName && attributeName ? `${listName}-${attributeName}` : ''
}

export const getCommonHeader = (): string[][] => {
    return [
        ['LINE_ID', 'DATX_ID', 'PART_NUMBER', 'ORG_NAME', 'MANUAL_DESCR_EN'],
        ['#', 'DATX IDENTIFICATION NUMBER', 'PART NUMBER', 'MANUFACTURER NAME', 'ITEM DESCRIPTION']
    ]
}

export const getCommonItemsForExcelForCreation = (items: PrivateAttributeManagement[]): (string | number)[][] => {
    const itemsForExcel = items.map((item, index) => ([
        index + 1,
        item.id,
        item.partNumber,
        item.definedByOrganization.label,
        item.description
    ]))
    return itemsForExcel
}

export const generateDownloadableExcelFile = (ws :  XLSX.WorkSheet, fileType: string, fileExtension : string , fileName : string ) => {
    const data = generateExcelData(ws, fileType)
    FileSaver.saveAs(data, fileName + fileExtension)
}

export const createExcelFileObject = (ws :  XLSX.WorkSheet, fileType: string, fileExtension : string , fileName : string ) : File => {
    const data = generateExcelData(ws, fileType)
    return new File([data], fileName + fileExtension, { type: fileType })
}

export const generateExcelData = (ws: WorkSheet, fileType: string)  => {
    const wb = {Sheets: {'data': ws}, SheetNames: ['data']}
    const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'})
    return new Blob([excelBuffer], {type: fileType})
}


