import React, {ChangeEvent, SyntheticEvent, useEffect} from 'react'
import {FormControl} from '@mui/material'
import UploadCommonLoadingButton from '../../../genericComponents/UploadCommonLoadingButton'
import {useFormikContext} from 'formik'
import {NewValueByItemDTO} from '../../../../model/private/NewValueByItemDTO'
import {ServiceRequestStep} from '../../../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Description} from '../../../../../genericComponents/typographie/Description'
import {Label} from '../../../../../genericComponents/typographie/Label'
import {AttributeDocumentCreationForm} from './PrivateAttributeDocumentCreation'
import {setSnackbarMessage} from '../../../../../genericComponents/commonSlice'
import {useDispatch} from 'react-redux'
import {useGetItemListAllPagesQuery} from '../../../../../itemList/rtkItemListApi'
import {useCurrentGuildContext} from '../../../../../util/routing'
import LoadingSpinner from '../../../../../genericComponents/LoadingSpinner'

export const UploadDocumentCreation = () => {
    const formik = useFormikContext<AttributeDocumentCreationForm>()
    const [currentGuildContext] = useCurrentGuildContext()

    const itemListLite = formik?.values?.itemList
    const dispatch = useDispatch()

    const {data: itemListFull, isLoading: itemListFullIsLoading} = useGetItemListAllPagesQuery(
        {
            listId: itemListLite?.id
        },
        {skip: !currentGuildContext?.guildId || !itemListLite}
    )

    useEffect(() =>{
        formik.setFieldValue('itemListWithItems', itemListFull)
    }, [itemListFull])

    const setArtifactFile = (artifactFile: File | undefined) => {
        formik.setFieldValue('artifactFile', artifactFile)
    }

    const setPrivateAttributes = (privateAttributes: NewValueByItemDTO[] | undefined) => {
        if (!privateAttributes || privateAttributes.length <= 0) return

        const privateAttributesToUpdate = privateAttributes.filter(
            (attribute) =>
                attribute.value !== null && attribute.value !== '' && attribute.value !== undefined && attribute.itemId
        )
        formik.setFieldValue('privateAttributes', privateAttributesToUpdate)
    }

    const unselectArtifactFile = (event: SyntheticEvent) => {
        setPrivateAttributes(undefined)
        setArtifactFile(undefined)
        event.preventDefault()
    }

    const parseArtifactFile = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target?.files?.length) return
        if (event.target?.files[0].size > 100000000) {
            dispatch(setSnackbarMessage('File selected is larger than 100 MB limit'))
            return
        }

        const tempFile = event.target.files[0]
        setArtifactFile(tempFile)
    }

    return (
        <ServiceRequestStep>
            <Description className="service-request-step-description">
                This document will be uploaded to all the items in your selected list.
            </Description>
            {itemListFullIsLoading ?
                <div className="service-request-form-step">
                    <LoadingSpinner/>
                </div>
                :
                <FormControl className="download-upload-template-container">
                    <Label className="file-download-upload-label" htmlFor="upload-file">
                        Upload Document:
                    </Label>
                    <UploadCommonLoadingButton
                        className="upload-template-button"
                        selectedFileName={formik?.values?.artifactFile?.name ?? ''}
                        unselectFile={unselectArtifactFile}
                        parseFile={parseArtifactFile}
                        acceptedFileType=".pdf, .txt, .ppt, .pptx, .doc, .docx, .xls, .xlsx"
                    />
                </FormControl>
            }
        </ServiceRequestStep>
    )
}
